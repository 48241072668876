import React from "react";
import PropTypes from "prop-types";
import { get, invoke } from "lodash";
import Tooltip from "../Tooltip/Tooltip";
import styles from "./MenuBar.module.scss";
import { ThemeContext } from "../ThemeManager/ThemeManager";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import { VERIFIED } from "./constants";
import moment from "moment";
import { FUNDING, SEND_REPORTS } from "../../routes/pages/constants";
import htmlParser from "html-react-parser";
import { ReactComponent as MoneyIcon } from "../../assets/logos/money-dollar.svg";
import { ReactComponent as ClipBoardIcon } from "../../img/streamingData.svg";
import { ReactComponent as DropDownArrow } from "../../assets/logos/dropDownArrow.svg";
import ReactModal from "react-modal";
import Image from "../Image";
import midLogo from "../../img/Rectangle108@2x.png";
import firstLogo from "../../img/Rectangle152@2x.png";
import secondLogo from "../../img/Rectangle153@2x.png";
import { getPartnerName } from "../ThemeManager/helper";
import { getModifiedValue } from "../../routes/pages/YourAdvance/helper";
import segment from "../../utils/segment";
import { DEFAULT_PARTNER } from "../../routes/pages/YourAdvance/constants";

class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileImg: "",
      activeArtist: "",
      pathname: "",
      moreOrLess: "more",
      isOpen: false,
    };
  }
  componentDidMount() {
    this.setState({ pathname: this.props.history.location.pathname });
  }

  handleOpenPopup = () => {
    this.setState({ isOpen: true });
  };
  handleCancelPopup = () => {
    this.setState({ isOpen: false });
  };
  getRoyaltyNumber = (data) => parseInt(get(data, "royalty", 0));

  saveFunding = () => {
    if (!this.props.isAdjustTerm) {
      segment.track.customizedFunding(
        {
          ...this.props,
          currentData: this.props.currentData,
          advanceData: this.props.advanceData,
        },
        { track: 2 },
      );
    }
    this.props.saveFundingAdvance();
  };

  showFullMenu = () => {
    new Set(document.getElementsByClassName(styles.collapseMenu)).forEach(
      (ele) => ele.classList.toggle(`${styles.active}`),
    );
    document
      .getElementsByClassName(`${styles.collapseArrow}`)[0]
      .classList.toggle(`${styles.arrowDown}`);
    document
      .getElementsByClassName(`${styles.totalEstimateContainer}`)[0]
      .classList.toggle(`${styles.active}`);

    get(this.context, "sidebarText") &&
      invoke(
        document.getElementsByClassName(
          `${styles.mobileCustomTextContainer}`,
        )[0],
        "classList.toggle",
        `${styles.active}`,
      );

    invoke(
      document.getElementsByClassName(`${styles.teaserOuterContainer}`)[0],
      "classList.toggle",
      `${styles.active}`,
    );
    invoke(
      document.getElementsByClassName(`${styles.partnerBar}`)[0],
      "classList.toggle",
      `${styles.active}`,
    );
  };

  getImage = () => (
    <>
      <Image
        src={firstLogo}
        alt="Artist Image"
        imageType="profile"
        className={styles.firstImage}
      />
      <Image
        src={midLogo}
        alt="Artist Image"
        imageType="profile"
        className={styles.secondImage}
      />
      <Image
        src={secondLogo}
        alt="Artist Image"
        imageType="profile"
        className={styles.thirdImage}
      />
    </>
  );
  renderPopup = () => (
    <ReactModal
      isOpen={this.state.isOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCancelPopup}
      className={styles.detailsPopupContainer}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.offerContainer}>
        <div className={styles.firstContainer}>{this.getImage()}</div>
        <div className={styles.secondContainer}>
          <span className={styles.firstHead}>
            Many artists get even more than their initial estimate
          </span>
          <span className={styles.secondHead}>Just follow these steps.</span>
        </div>
        <div className={styles.thirdContainer}>
          <div className={styles.first}>
            <p>1</p>
            <span>Choose the advance structure that’s right for you</span>
          </div>
          <div className={styles.first}>
            <p>2</p>
            <span>Submit your distribution reports for review</span>
          </div>
          <div className={styles.first}>
            <p>3</p>
            <span>We’ll get you confirmed offers in 2-3 days</span>
          </div>
        </div>

        <div className={styles.fourthContainer}>
          <span onClick={this.handleCancelPopup}>GOT IT</span>
        </div>
      </div>
    </ReactModal>
  );
  warningDiv = () => (
    <div className={styles.mainWarningDiv}>
      <p className={styles.text}>
        {this.props.agentCode
          ? `beatBread is a direct to artists platform. Estimates and offers
        presented outside of our app by unauthorized representatives will not be
        honored.`
          : `beatBread is a direct to artists platform. Estimates and offers
          presented outside of our app by unauthorized representatives may not be
          honored and be invalidated by beatBread.`}
      </p>
    </div>
  );

  getButtonName = () =>
    this.props.agentCode ? `VIEW OFFER LETTER` : `SEE MORE DETAILS`;

  getConfirmedPartnerDigits = () => {
    return (
      <>
        <div className={styles.menuBarScroll}>
          <div className={`${styles.otherValues} ${styles.collapseMenu}`}>
            <ul>
              <li
                className={`${
                  !this.props.currentData.catalog_advance ? "d-none" : ""
                }`}
              >
                <span className={styles.countText}>Upfront advance</span>
                <div className={styles.fundingValues}>
                  $
                  <Odometer
                    value={
                      this.props.isCashFlow
                        ? getModifiedValue(
                            this.props.currentData,
                            this.props.cashflow_option_selected,
                          ).catalog_advance
                        : this.props.currentData.catalog_advance
                    }
                    format="(,ddd).dd"
                    duration={400}
                  />
                </div>
              </li>
              <li
                className={`${
                  !this.props.currentData.nr_advance ? "d-none" : ""
                }`}
              >
                <span className={styles.countText}>New release advance</span>
                <div className={styles.fundingValues}>
                  $
                  <Odometer
                    value={
                      this.props.isCashFlow
                        ? getModifiedValue(
                            this.props.currentData,
                            this.props.cashflow_option_selected,
                          ).nr_advance
                        : this.props.currentData.nr_advance
                    }
                    format="(,ddd).dd"
                    duration={400}
                  />
                </div>
              </li>
              <li
                className={`${
                  !this.props.currentData.option_advance ? "d-none" : ""
                }`}
              >
                <span className={styles.countText}>Option advance</span>
                <div className={styles.fundingValues}>
                  $
                  <Odometer
                    value={
                      this.props.isCashFlow
                        ? getModifiedValue(
                            this.props.currentData,
                            this.props.cashflow_option_selected,
                          ).option_advance
                        : this.props.currentData.option_advance
                    }
                    format="(,ddd).dd"
                    duration={400}
                  />
                </div>
              </li>
              <li
                className={`${
                  !parseFloat(this.props.currentData.royalty) ? "d-none" : ""
                }`}
              >
                <span className={styles.countText}>
                  Recoupment rate{" "}
                  <Tooltip
                    place="top"
                    light
                    id="royaltyPercent"
                    content={
                      <span>
                        This is the percentage of your distribution revenue that
                        we collect that goes toward recouping your advance.
                      </span>
                    }
                    delay={200}
                  />
                </span>
                <div className={styles.fundingValues}>
                  <Odometer
                    value={
                      this.props.isCashFlow
                        ? getModifiedValue(
                            this.props.currentData,
                            this.props.cashflow_option_selected,
                          ).royalty
                        : this.getRoyaltyNumber(this.props.currentData)
                    }
                    format="(,ddd).dd"
                    duration={400}
                  />
                  %
                </div>
              </li>
              {!!get(this.props, "currentData.fees") && (
                <li>
                  <span className={styles.countText}>
                    Distribution Fee{" "}
                    <Tooltip
                      place="top"
                      light
                      id="distributionFees"
                      content={
                        "This is the annual cost charged by the distributor for their services."
                      }
                      delay={200}
                    />
                  </span>
                  <div className={styles.fundingValues}>
                    {get(this.props, "currentData.fees")}
                  </div>
                </li>
              )}
              {!!get(this.props, "currentData.featuresHTML") && (
                <li>
                  <span className={styles.countText}>
                    Features and Services{" "}
                  </span>
                  <button
                    data-testid="view-features"
                    onClick={() => {
                      invoke(
                        this.props,
                        "toggleFeatureModal",
                        get(this.props, "currentData.partner"),
                      );
                    }}
                    className={`${styles.fundingValues} ${styles.linkBtn}`}
                  >
                    View Features
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className={`${styles.totalEstimateContainer}`}>
          <span className={`${styles.countText} ${styles.bold}`}>
            Confirmed Total
          </span>
          <div className={styles.fundingValues}>
            $
            <Odometer
              value={
                this.props.isCashFlow
                  ? getModifiedValue(
                      this.props.currentData,
                      this.props.cashflow_option_selected,
                    ).total_advance
                  : this.props.currentData.total_advance
              }
              format="(,ddd).dd"
              duration={400}
            />
          </div>
        </div>
        <div className={`${styles.btnContainer} ${styles.btnColumn}`}>
          <span>Continue with {get(this.props, "currentData.partner")}?</span>
          <button
            className={styles.primary}
            data-testid="save-offer-btn"
            onClick={() => invoke(this.props, "saveFundingAdvance", false)}
          >
            NEXT
          </button>
          <button
            className={styles.link}
            data-testid="reject-offer-btn"
            onClick={() => invoke(this.props, "toogleRejectOffer", false)}
          >
            Decline Offers
          </button>
        </div>
      </>
    );
  };

  getEstimatedDigits = () => {
    if (this.props.currentData) {
      return (
        <>
          <div className={styles.menuBarScroll}>
            <div className={`${styles.otherValues} ${styles.collapseMenu}`}>
              <ul>
                <li
                  className={`${
                    !this.props.currentData.catalog_advance ? "d-none" : ""
                  }`}
                >
                  <span className={styles.countText}>Upfront advance</span>
                  <div className={styles.fundingValues}>
                    $
                    <Odometer
                      value={
                        this.props.isCashFlow
                          ? getModifiedValue(
                              this.props.currentData,
                              this.props.cashflow_option_selected,
                            ).catalog_advance
                          : this.props.currentData.catalog_advance
                      }
                      format="(,ddd).dd"
                      duration={400}
                    />
                  </div>
                </li>
                <li
                  className={`${
                    !this.props.currentData.nr_advance ? "d-none" : ""
                  }`}
                >
                  <span className={styles.countText}>New release advance</span>
                  <div className={styles.fundingValues}>
                    $
                    <Odometer
                      value={
                        this.props.isCashFlow
                          ? getModifiedValue(
                              this.props.currentData,
                              this.props.cashflow_option_selected,
                            ).nr_advance
                          : this.props.currentData.nr_advance
                      }
                      format="(,ddd).dd"
                      duration={400}
                    />
                  </div>
                </li>
                <li
                  className={`${
                    !this.props.currentData.option_advance ? "d-none" : ""
                  }`}
                >
                  <span className={styles.countText}>Option advance</span>
                  <div className={styles.fundingValues}>
                    $
                    <Odometer
                      value={
                        this.props.isCashFlow
                          ? getModifiedValue(
                              this.props.currentData,
                              this.props.cashflow_option_selected,
                            ).option_advance
                          : this.props.currentData.option_advance
                      }
                      format="(,ddd).dd"
                      duration={400}
                    />
                  </div>
                </li>
                <li
                  className={`${
                    !parseFloat(this.props.currentData.royalty) ? "d-none" : ""
                  }`}
                >
                  <span className={styles.countText}>
                    Recoupment rate{" "}
                    <Tooltip
                      place="top"
                      light
                      id="royaltyPercent"
                      content={
                        "This is the percentage of your distribution revenue that we collect that goes toward recouping your advance."
                      }
                      delay={200}
                    />
                  </span>
                  <div className={styles.fundingValues}>
                    <Odometer
                      value={
                        this.props.isCashFlow
                          ? getModifiedValue(
                              this.props.currentData,
                              this.props.cashflow_option_selected,
                            ).royalty
                          : this.getRoyaltyNumber(this.props.currentData)
                      }
                      format="(,ddd).dd"
                      duration={400}
                    />
                    %
                  </div>
                </li>
              </ul>
            </div>
            {this.props.currentData?.term && (
              <div
                className={`${styles.artistIncomeContainer} ${styles.collapseMenu}`}
              >
                <div className={styles.artistIncomeBox}>
                  <p>Artist Income</p>
                </div>
                <div>
                  <div className={styles.artistIncome}>
                    <p className={styles.artistIncomeValue}>
                      {this.props.currentData?.artist_share_pre_recoup}%
                    </p>
                    <span>Before Recoupment</span>
                  </div>
                  <div className={styles.artistIncome}>
                    <p className={styles.artistIncomeValue}>
                      {this.props.currentData?.artist_share_post_recoup}%
                    </p>
                    <span>
                      If recouped before {this.props.currentData?.term} years
                    </span>
                  </div>
                  <div className={styles.artistIncome}>
                    <p className={styles.artistIncomeValue}>
                      {this.props.currentData?.income_catalog_post_term}%
                    </p>
                    <span>At the end of the deal</span>
                  </div>
                </div>
              </div>
            )}
            {get(this.context, "sidebarText") && (
              <div className={styles.mobileCustomTextContainer}>
                {htmlParser(get(this.context, "sidebarText", ""))}
              </div>
            )}
          </div>
          <div className={`${styles.totalEstimateContainer}`}>
            <span className={`${styles.countText} ${styles.bold}`}>
              {!this.props.isAdjustTerm ? `Estimated Total` : `Confirmed Total`}
            </span>
            <div className={styles.fundingValues}>
              $
              <Odometer
                value={
                  this.props.isCashFlow
                    ? getModifiedValue(
                        this.props.currentData,
                        this.props.cashflow_option_selected,
                      ).total_advance
                    : this.props.currentData.total_advance
                }
                format="(,ddd).dd"
                duration={400}
              />
            </div>
          </div>
          {get(this.context, "sidebarText") && (
            <div className={styles.customTextContainer}>
              {htmlParser(get(this.context, "sidebarText", ""))}
            </div>
          )}
          {!this.props.isAdjustTerm && (
            <div
              className={`${styles.teaserOuterContainer}  ${
                this.props.isAdjustTerm && styles.padding
              }`}
            >
              <div className={styles.teaserContainer}>
                <MoneyIcon />

                <div className={`${styles.teaserText}`}>
                  Many artists qualify for even better terms.{" "}
                  <span onClick={this.handleOpenPopup} data-testid="span-see">
                    See How
                  </span>{" "}
                </div>
              </div>
            </div>
          )}
          {this.props.history.location.pathname === FUNDING ? (
            <div className={styles.btnContainer}>
              <button
                className={styles.primary}
                data-testid="saveFunding"
                onClick={() => this.saveFunding()}
              >
                CONTINUE TO UPLOAD REPORTS
              </button>
            </div>
          ) : (
            <div className={`${styles.btnContainer}`}>
              <button
                className={styles.primary}
                onClick={() => this.props.saveFundingAdvance(false)}
              >
                {this.getButtonName()}
              </button>
              <button
                className={styles.link}
                data-testid="reject-offer-btn"
                onClick={() => invoke(this.props, "toogleRejectOffer", false)}
              >
                Decline Offers
              </button>
              <span
                className={styles.fineText}
                onClick={this.props.toggleFinePrintText}
              >
                Read the fine print
              </span>
            </div>
          )}
          {this.props.isAdjustTerm &&
            getPartnerName() === "beatBread" &&
            this.warningDiv()}
        </>
      );
    }
    if (
      (this.props.isDemo &&
        !!get(this.props.offerStage, "defaultOffer") &&
        get(this.props, "history.location.pathname") === SEND_REPORTS) ||
      get(this.props.offerStage, "verificationStatus.streamingData.value") ===
        VERIFIED
    ) {
      return (
        <>
          <div className={styles.odometerContainer}>
            $
            <Odometer
              value={this.props.offerStage.defaultOffer.totalAdvance}
              format="(,ddd).dd"
              duration={400}
            />
            <span className={`${styles.countText} ${styles.textBig}`}>
              total
            </span>
          </div>
          <div className={styles.otherValues}>
            <ul>
              <li
                className={`${
                  !this.props.offerStage.defaultOffer.upfront ? "d-none" : ""
                }`}
              >
                <div>
                  $
                  <Odometer
                    value={this.props.offerStage.defaultOffer.upfront}
                    format="(,ddd).dd"
                    duration={400}
                  />
                </div>
                <span className={styles.countText}>Upfront advance</span>
              </li>
              <li
                className={`${
                  !this.props.offerStage.defaultOffer.newRelease ? "d-none" : ""
                }`}
              >
                <div>
                  $
                  <Odometer
                    value={this.props.offerStage.defaultOffer.newRelease}
                    format="(,ddd).dd"
                    duration={400}
                  />
                </div>
                <span className={styles.countText}>New release advance</span>
              </li>
              <li
                className={`${
                  !this.props.offerStage.defaultOffer.optionalRelease
                    ? "d-none"
                    : ""
                }`}
              >
                <div>
                  $
                  <Odometer
                    value={this.props.offerStage.defaultOffer.optionalRelease}
                    format="(,ddd).dd"
                    duration={400}
                  />
                </div>
                <span className={styles.countText}>Option advance</span>
              </li>
              <li
                className={`${
                  !parseFloat(this.props.offerStage.defaultOffer.royalty)
                    ? "d-none"
                    : ""
                }`}
              >
                <div>
                  <Odometer
                    value={this.getRoyaltyNumber(
                      this.props.offerStage.defaultOffer,
                    )}
                    format="(,ddd).dd"
                    duration={400}
                  />
                  %
                </div>
                <span className={styles.countText}>
                  Recoupment rate{" "}
                  <Tooltip
                    place="top"
                    light
                    id="royaltyPercent"
                    content={
                      "This is the percentage of your distribution revenue that we collect that goes toward recouping your advance."
                    }
                    delay={200}
                  />
                </span>
              </li>
            </ul>
          </div>
        </>
      );
    }
    return <div className={styles.odometerContainer}>$--,--- Total</div>;
  };

  toggleSeeMore = () => {
    this.setState((prevState) => ({
      moreOrLess: prevState.moreOrLess === "more" ? "less" : "more",
    }));
    document
      .getElementsByClassName(`${styles.digitDetails}`)[0]
      .classList.toggle(`${styles.showMore}`);
    document
      .getElementsByClassName(`${styles.seeMoreLnk}`)[0]
      .classList.toggle(`${styles.showMore}`);
  };

  getTooltipTitle = () =>
    this.props.isAdjustTerm
      ? `Confirmed Offer Summary`
      : `Initial Funding Estimate`;

  renderPartner = () =>
    get(this.props, "currentData.partner") === DEFAULT_PARTNER ? (
      <span>Current Distributor</span>
    ) : (
      <>
        <Image
          src={get(this.props, "currentData.logo")}
          alt="Partner Logo"
          className={styles.distributorImg}
        />
        <span>{get(this.props, "currentData.partner")}</span>
      </>
    );

  renderOfferSection = () =>
    this.isPartner() ? (
      <div className={`${styles.container} ${styles.partnerBar}`}>
        <div className={styles.collapseArrow} onClick={this.showFullMenu}>
          <DropDownArrow /> <span />
          <DropDownArrow />
        </div>
        <div className={`${styles.menuBarContainerBox} ${styles.collapseMenu}`}>
          <div className={styles.digitDetails}>
            <div className={styles.menuToolTip}>
              <p className={`mb-0 ${styles.textSmall}`}>Your Offers</p>
              <Tooltip
                place="right"
                dark
                id="partnerOffers"
                content={
                  "This is the finalized advance amount based on your verified ownership."
                }
                delay={200}
              />
            </div>
            <div className={styles.partnerDetails}>{this.renderPartner()}</div>
          </div>
        </div>
        <div className={styles.youraccountCount}>
          {this.getConfirmedPartnerDigits()}
        </div>
      </div>
    ) : (
      <div className={`${styles.container} ${styles.partnerBar}`}>
        <div className={styles.collapseArrow} onClick={this.showFullMenu}>
          <DropDownArrow /> <span />
          <DropDownArrow />
        </div>
        <div className={`${styles.menuBarContainerBox} ${styles.collapseMenu}`}>
          <div className={styles.digitDetails}>
            <div className={styles.menuToolTip}>
              <p className={`mb-0`}>{this.getTooltipTitle()}</p>
              <Tooltip
                place="right"
                dark
                id="estimatedFunding"
                content={
                  "This is the finalized advance amount based on your verified ownership."
                }
                delay={200}
              />
            </div>
            <div>
              {get(this.props.currentData, "expire") && (
                <span className={styles.validThrough}>
                  Valid through{" "}
                  {moment(get(this.props.currentData, "expire")).format(
                    "MMMM DD, YYYY",
                  )}
                </span>
              )}
              {get(
                this.props.offerStage,
                "verificationStatus.streamingData.value",
              ) === VERIFIED &&
                get(this.props.offerStage.defaultOffer, "expire") &&
                !get(this.props.currentData, "expire") && (
                  <span className={styles.validThrough}>
                    Valid through{" "}
                    {moment(
                      get(this.props.offerStage.defaultOffer, "expire"),
                    ).format("MMMM DD, YYYY")}
                  </span>
                )}
            </div>
            <div className={styles.dealDetails}>Deal Summary</div>
          </div>
          <div className={styles.youraccountCount}>
            {this.getEstimatedDigits()}
            {this.renderPopup()}
          </div>
        </div>
      </div>
    );

  isPartner = () =>
    get(this.props, "availablePartners.length") &&
    get(this.props, "isAdjustTerm");

  render() {
    return (
      <div
        className={`${styles.mainContainer} ${
          this.isPartner() ? styles.partnerFlow : styles.offerSummary
        }`}
      >
        {!this.isPartner() && this.props.isAdjustTerm && (
          <div className={styles.helperText}>
            <ClipBoardIcon className={styles.svgIcon} />
            <div className={styles.text}>
              Confirmed Offers are Subject to Validation and Final Contract
            </div>
          </div>
        )}
        {this.renderOfferSection()}
      </div>
    );
  }
}

MenuBar.contextType = ThemeContext;
export default MenuBar;

MenuBar.contextTypes = {
  sidebarText: PropTypes.string,
};
