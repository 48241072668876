export const DEFAULT_FLOW_THROUGH = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];

export const DEFAULT_TERMS = [1, 2, 3, 5, 8, 20];

export const DEFAULT_ROYALTY = [50, 60, 70, 80, 90];

export const OFFERSUMMARY_STEPS = [
  "MAIN_STEP",
  "DEAL_QUESTION",
  "MUSIC_QUESTION",
  "INCLUDE_UNRELEASE_SONG",
  "DEAL_LENGTH_QUESTION",
  "FUNDING_QUESTION",
  "INCOME_QUESTION",
];

export const CONFIRMED_OFFER_QUESTIONS = {
  MAIN_QUESTION: "How do you want to choose your confirmed offers?",
  DEAL_QUESTION: "What do you value most in your deal?",
  MUSIC_QUESTION: "Do you want funding on new music?",
  INCLUDE_UNRELEASE_SONG_QUESTION:
    "How many unreleased songs do you want to include?",
  DEAL_LENGTH_QUESTION: "How long do you want to be in your deal?",
  INCOME_QUESTION: "How much income do you want to keep during your deal?",
  FUNDING_SLIDER_QUESTION: "How much funding do you need?",
};

export const MAIN_QUESTION_OPTIONS = [
  {
    OPTION: "View Recommended Deals",
    DESCRIPTION: "Answer a few questions to find the deal best for you",
  },
  {
    OPTION: "Select Advance amount",
    DESCRIPTION: "Choose exactly how much funding you need",
  },
  {
    OPTION: "Build your offer",
    DESCRIPTION: "Fully customize every term in your deal",
  },
];

export const DEAL_OPTIONS = [
  {
    OPTION: "Getting the most funding",
    DESCRIPTION: "I want the largest amount of funding possible",
  },
  {
    OPTION: "The Length of Deal",
    DESCRIPTION: "I don’t want to be in a deal for too long",
  },
  {
    OPTION: "Keeping some income",
    DESCRIPTION: "I still want to receive royalties during my deal",
  },
];

export const MUSIC_OPTION = [
  {
    OPTION: "Yes",
  },
  { OPTION: "No" },
];

export const INCLUDE_UNRELEASE_SONG_OPTION = [
  {
    OPTION: "5 new songs",
  },
  {
    OPTION: "10 new songs",
  },
  {
    OPTION: "15 new songs",
  },
  {
    OPTION: "20 new songs",
  },
];

export const DEAL_LENGTH_OPTIONS = [
  {
    OPTION: "1 year",
  },
  {
    OPTION: "2 years",
  },
  {
    OPTION: "3 years",
  },
  {
    OPTION: "5 years",
  },
  {
    OPTION: "8 years",
  },
];

export const INCOME_OPTIONS = [
  {
    OPTION: "10%",
  },
  {
    OPTION: "20%",
  },
  {
    OPTION: "30%",
  },
  {
    OPTION: "40%",
  },
  {
    OPTION: "50%",
  },
];

export const SLIDER_STEP_RANGE = [25000, 100000, 500000];
