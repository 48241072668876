import React, { Component } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Tooltip from "../../../component/Tooltip/Tooltip";
import { get, debounce, unset, flatMapDeep, keyBy, invoke } from "lodash";
import styles from "./YourAdvance.module.scss";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  TOP_TRACK,
  OFFERS_API,
  SEND_REPORTS,
  TUNE_MY_DATA,
  OFFER_SUMMARY,
  ERROR_SERVER_DOWN,
  ACCEPT_OFFER,
  GENERATING_OFFERS,
  LINK_SPOTIFY,
  ESTIMATED_OFFER,
  DISTRIBUTOR_FEATURE_API,
  FUNDING,
  REJECT_OFFER,
  PUBLISHING_REPORTS,
  PROS_API,
  ACTIVE_DISTRIBUTORS,
  MASTERS_OFFERING_API,
  HOME,
  CONFIRM_OWNERSHIP,
  CONFIRMED_OFFERS_V2,
} from "../constants";
import LoginHeader from "../../../component/LoginHeader";
import Loader from "../../../component/Loader";
import MenuBar from "../../../component/MenuBar";
import request from "../../../utils/request";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import {
  FunnelCollapseGuide,
  getSliderComponent,
  changeSliderCallback,
  getNewReleaseLabel,
  isModifiedFlow,
  checkTermCondition,
  FinePrintModal,
  RejectOfferSurveyModal,
} from "./helper";
import ShortCallLoader from "../../../component/Loader/ShortCallLoader";
import ContactPopup from "../../../component/ContactPopup";
import { GetErrorMessage } from "../helper";
import { VERIFIED } from "../../../component/MenuBar/constants";
import HorizontalMenu from "../../../component/HorizontalMenu";
import { BB_SUBDOMAINS } from "../../../component/ThemeManager/constants";
import { getSubDomain, setTitle } from "../../../component/ThemeManager/helper";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import { ARROW_FORWARD } from "../../../component/MaterialIcons/constants";
import {
  CASHFLOW,
  DEFAULT_RIGHTS,
  POR_DEFAULT_OPTION,
  PRO_OTHER_OPTION,
  PUBLISHING_DEFAULT_OPTION,
  PUBLISHING_FIELDS,
  PUBLISHING_INITIAL_VALUES,
  PUBLISHING_STREAMING_INCOME_MULTIPLIER,
  RIGHTS_TYPES,
  TYPE_PRO,
} from "./constants";
import ReviewEstimateContainer from "./ReviewEstimateContainer";
import segment from "../../../utils/segment";
import PartnerSelect from "./PartnerSelect";
import PartnerComparisonTool, {
  FeatureDataModal,
} from "./PartnerComparisonTool";
import { ReactComponent as BeatBreadLogo } from "../../../img//beatBreadlogo.svg";
import { ReactComponent as PlusIcon } from "../../../assets/logos/plus-Icon.svg";
import FormikForm from "../../../component/Form/FormikForm";
import { Form, Formik } from "formik";
import FormField from "../../../component/FormField/FormField";
import Image from "../../../component/Image";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import { RIGHTS_INCLUDED_CONTENT } from "../infoIconMsg";
import ReactModal from "react-modal";
import {
  MASTERS_INIT_DATA,
  SWITCH_DIST_OPTIONS,
} from "../Auth/SignupFastFlow/constants";
import {
  CHOOSE_PATH_FIELDS,
  ESTIMATES_FIELD_NAMES,
  MAJOR_DISTRIBUTOR_OPTIONS,
} from "../Auth/LinkAnotherArtist/constant";
import Switch from "../AdminDashboard/Switch";
import ShareOtherOffer from "./ShareOtherOffers";

class YourAdvance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      shortLoading: false,
      newTracks: 0,
      term: 1,
      catalogIncome: 0,
      releaseIncome: 0,
      advanceData: {},
      streamingVolume: "",
      artistProfile: {},
      currentData: {},
      defaultOffer: {},
      isOpen: false,
      offerStage: {},
      dummyArtist: get(props.history.location, "state.isDemo", false),
      guideData: {},
      navigationStatus: {},
      agentCode: null,
      isCashFlow: false,
      selectedArtist: {},
      flowthrough: 0,
      publishingToggle: false,
      availablePartners: [],
      selectedPartner: null,
      isInitialPartner: true,
      advanceDifference: 0,
      partnersOptions: {},
      streamingIncome: 0,
      isComparePartner: false,
      viewedComparisonTool: false,
      isFeatureModalOpen: false,
      featureData: null,
      isFinePrint: false,
      showPublishingBtn: false,
      addPublisher: false,
      rightsType: DEFAULT_RIGHTS,
      isRejectOfferModalOpen: false,
      email: "",
      rightsIncludedOptions: RIGHTS_TYPES,
      publishingModal: false,
      proOptions: [POR_DEFAULT_OPTION],
      publisherOptions: [],
      requestId: "",
      publishingRequestId: "",
      distributorsOptions: [],
      switchDistributor: SWITCH_DIST_OPTIONS[1].value,
      isSwitchDistributor: false,
      isShareOfferModalOpen: false,
    };
    this.getSaveFundingAdvance = debounce(
      () => this.saveFundingAdvance(true),
      5000,
    );
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    this.getAdvanceData();
  }

  componentDidUpdate() {
    this.isAdjustTerm()
      ? setTitle("Confirmed Offers", this.context)
      : setTitle("Funding", this.context);
  }

  isAdjustTerm = () => this.props.history.location.pathname === OFFER_SUMMARY;

  checkIsFastFlow = () => {
    return get(this.state.navigationStatus, "isFastFlow", false) ? true : false;
  };

  isPartnerFlow = () =>
    !!get(this.state, "availablePartners.length") && this.isAdjustTerm();

  loaderText = () => {
    if (this.props.history.location.pathname === OFFER_SUMMARY) {
      if (this.state.isSaveOffer) {
        return (
          <p>
            We are generating <br /> your<span> deal summary</span>
          </p>
        );
      } else {
        return (
          <p>
            We are retrieving <br /> your<span> offers</span>
          </p>
        );
      }
    } else {
      return (
        <p>
          We are generating <br /> your<span> estimates</span>
        </p>
      );
    }
  };

  getPartnerOptionObject = (data) => {
    const availablePartners = this.state.availablePartners.map((op) =>
      get(flatMapDeep(data.offers[op]), `0.1.0.${null}`),
    );
    return keyBy(availablePartners, "partner");
  };

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          if (get(res.data, "dummy_artist", false)) {
            this.setState({
              offerStage: res.data.offerStage,
              dummyArtist: get(res.data, "dummy_artist", false),
              guideData: {
                funnelGuideCustomization: get(
                  res.data,
                  "funnelGuideCustomization",
                  "",
                ),
                funnelGuideSendReports: get(
                  res.data,
                  "funnelGuideSendReports",
                  "",
                ),
                funnelGuideIncomeVerification: get(
                  res.data,
                  "funnelGuideIncomeVerification",
                  "",
                ),
                funnelGuideVerification2: get(
                  res.data,
                  "funnelGuideVerification2",
                  "",
                ),
              },
            });
            this.getOffers(true);
            return true;
          }
          if (!this.isAdjustTerm()) {
            this.setState({
              navigationStatus: {
                isFastFlow: get(res.data, "isFastFlow", false),
              },
              streamingVolume: res.data.streaming_volume,
              streamingIncome: res.data.streamingIncome,
              offerStage: res.data.offerStage,
              artistProfile: {
                url: get(
                  res.data,
                  `profilePicture[${
                    get(res.data, "profilePicture", [{}]).length - 1
                  }].url`,
                  "",
                ),
                name: res.data.name,
              },
              addPublisher: get(res, "data.addPublisher", false),
              requestId: get(res, "data.requestId", ""),
              publishingRequestId: get(res, "data.publishingRequestId", ""),
            });
            this.setRightsIncludedOptions(get(res, "data", {}));
            this.getOffersFirst(
              res.data,
              get(res.data, "offerStage.verificationStatus"),
              get(this.props, "location.state.fromDashboard"),
            );
            return true;
          } else {
            this.checkSteps(
              res.data,
              get(this.props, "location.state.fromDashboard"),
            );
            if (get(res.data, "renderNewConfirmedOffers")) {
              return false;
            }
          }
          this.setState({
            streamingVolume: res.data.streaming_volume,
            offerStage: res.data.offerStage,
            offerMessage: res.data.approval_message,
            availablePartners: get(res, "data.availablePartners.length")
              ? [...get(res, "data.availablePartners")]
              : [],
            advanceDifference: get(res.data, "advance_diff", 0),
            artistProfile: {
              url: get(
                res.data,
                `profilePicture[${
                  get(res.data, "profilePicture", [{}]).length - 1
                }].url`,
                "",
              ),
              name: res.data.name,
            },
          });
          this.getOffers(true);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  checkSteps = (data, fromDashboard) => {
    if (fromDashboard) {
      if (
        get(
          data,
          "offerStage.defaultOffer.isAccepted" ||
            (get(data, "offerStage.verificationStatus.adjustTerms") &&
              get(data, "offerStage.verificationStatus.offerSummary")),
        )
      ) {
        this.props.history.push({
          pathname: ACCEPT_OFFER,
          state: { reviewData: data },
        });
        return false;
      }
    }
    if (get(data, "renderNewConfirmedOffers")) {
      this.props.history.push(CONFIRMED_OFFERS_V2);
    }
    return true;
  };

  getDivideBy(newObj) {
    if (newObj.hasOwnProperty("term")) {
      newObj.term /= 12;
    }
  }

  convertKeys(obj) {
    const convertedObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (isNaN(key)) {
          convertedObj[key] = this.convertKeys(obj[key]);
        } else {
          const newKey = (parseInt(key) / 12).toString();
          const newObj = { ...obj[key] };
          for (const objKey in newObj) {
            if (newObj.hasOwnProperty(objKey)) {
              this.getDivideBy(newObj[objKey]);
            }
          }
          convertedObj[newKey] = newObj;
        }
      }
    }
    return convertedObj;
  }

  getYearData = (val) => {
    for (let i = 0; i < Object.keys(val).length; i++) {
      const newObj = this.convertKeys(val[Object.keys(val)[i]]);
      val[Object.keys(val)[i]] = newObj;
    }
  };

  handlePayloadObj = (obj) => {
    const newObj = { ...obj };
    if (newObj.hasOwnProperty("term")) {
      newObj.term *= 12;
    }
    return newObj;
  };

  getOffers = (loadDefault, rightsType) => {
    this.setState({ loading: false, shortLoading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = this.checkIsFastFlow()
      ? `${API_URL}${USER_API}${ARTIST_API}${ESTIMATED_OFFER}`
      : `${API_URL}${USER_API}${ARTIST_API}${OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false, shortLoading: false });
        if (res.status) {
          if (this.props.history.location.pathname === FUNDING) {
            segment.track.viewedQuote(
              {
                advanceData: res.data.offers,
                defaultOffer: res.data.defaultOffer,
                state: this.state,
              },
              this.checkIsFastFlow(),
            );
          } else if (this.props.history.location.pathname === OFFER_SUMMARY) {
            segment.track.viewedFinalOffer(
              {
                advanceData: res.data.offers,
                defaultOffer: res.data.defaultOffer,
                state: this.state,
              },
              this.checkIsFastFlow(),
            );
          }

          if (this.checkIsFastFlow()) {
            !checkTermCondition(res.data.offers) &&
              unset(res.data, "offers.flow_through.240") &&
              unset(res.data, "offers.c.240");
            this.getDivideBy(res.data.defaultOffer);
            this.getYearData(res.data.offers);
            this.setState({
              publishingToggle: get(res.data, "publishAdvance", false),
            });
          }
          this.setState(
            {
              advanceData: res.data.offers,
              defaultOffer: res.data.defaultOffer,
              selectedPartner: get(
                res,
                "data.defaultOffer.partner",
                get(this.state, "availablePartners[0]", null),
              ),
              partnersOptions: this.getPartnerOptionObject(
                get(res, "data", {}),
              ),
              rightsType:
                rightsType ||
                get(res, "data.defaultOffer.rights_type", DEFAULT_RIGHTS),
            },
            () => changeSliderCallback.bind(this)(loadDefault),
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false, shortLoading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  goToPendingStep = (data, verificationStatus) => {
    if (
      !this.checkIsFastFlow() &&
      get(verificationStatus, "streamingData.value") !== VERIFIED
    ) {
      this.props.history.push({
        pathname: TUNE_MY_DATA,
        state: { reviewData: data },
      });
    } else if (get(verificationStatus, "funding.value") !== VERIFIED) {
      this.setState({ loading: true });
      this.getOffers(true);
    } else {
      if (get(data, "addPublisher")) {
        if (
          get(data, "estimatedOffer.initial_rights_type") ===
          RIGHTS_TYPES[0].value
        ) {
          if (get(verificationStatus, "reports")) {
            if (
              get(this.props, "location.state.navigationData.confirmOwnership")
            ) {
              this.props.history.push({ pathname: CONFIRM_OWNERSHIP });
              return false;
            }
            this.props.history.push({ pathname: GENERATING_OFFERS });
            return false;
          }
          if (get(verificationStatus, "publisherReport")) {
            this.props.history.push({ pathname: SEND_REPORTS });
            return false;
          }
          this.props.history.push({ pathname: PUBLISHING_REPORTS });
          return false;
        }
        if (
          get(data, "estimatedOffer.offer.rights_type") ===
          RIGHTS_TYPES[1].value
        ) {
          if (get(verificationStatus, "reports")) {
            if (
              get(this.props, "location.state.navigationData.confirmOwnership")
            ) {
              this.props.history.push({ pathname: CONFIRM_OWNERSHIP });
              return false;
            }
            this.props.history.push({ pathname: GENERATING_OFFERS });
            return false;
          }
          this.props.history.push({ pathname: SEND_REPORTS });
          return false;
        }
        if (get(verificationStatus, "publisherReport")) {
          if (
            get(this.props, "location.state.navigationData.confirmOwnership")
          ) {
            this.props.history.push({ pathname: CONFIRM_OWNERSHIP });
            return false;
          }
          this.props.history.push({ pathname: GENERATING_OFFERS });
          return false;
        }
        if (get(verificationStatus, "reports")) {
          this.props.history.push({ pathname: PUBLISHING_REPORTS });
          return false;
        }
        this.props.history.push({ pathname: SEND_REPORTS });
        return false;
      }
      if (!get(verificationStatus, "reports")) {
        this.props.history.push({
          pathname: SEND_REPORTS,
        });
      } else if (!get(verificationStatus, "finishingTouch")) {
        if (this.checkIsFastFlow()) {
          if (
            get(this.props, "location.state.navigationData.confirmOwnership")
          ) {
            this.props.history.push({ pathname: CONFIRM_OWNERSHIP });
            return false;
          }
          this.props.history.push({
            pathname: GENERATING_OFFERS,
          });
        } else {
          this.props.history.push({
            pathname: LINK_SPOTIFY,
          });
        }
      } else if (get(verificationStatus, "finishingTouch")) {
        if (get(this.props, "location.state.navigationData.confirmOwnership")) {
          this.props.history.push({ pathname: CONFIRM_OWNERSHIP });
          return false;
        }
        this.props.history.push({
          pathname: GENERATING_OFFERS,
        });
      } else {
        this.setState({ loading: true });
        this.getOffers(true);
      }
    }
  };

  getOffersFirst = (data, verificationStatus, fromDashboard) => {
    if (fromDashboard) {
      this.goToPendingStep(data, verificationStatus);
      return true;
    }
    if (
      get(data, "isFastFlow") &&
      get(data, "choosePath") === CHOOSE_PATH_FIELDS().OPTIONS[0].value
    ) {
      this.props.history.replace(HOME);
      return true;
    }
    if (get(verificationStatus, "streamingData.value") === VERIFIED) {
      this.setState({ loading: true });
      this.getOffers(true);
      if (get(this.props.history.location, "state.redirectMsg")) {
        toast.error(get(this.props.history.location, "state.redirectMsg"));
        const state = { ...this.props.history.location.state };
        delete state.redirectMsg;
        this.props.history.replace({ ...this.props.history.location, state });
      }
      return true;
    }
    if (
      get(verificationStatus, "streamingData.value") !== VERIFIED &&
      this.checkIsFastFlow()
    ) {
      this.setState({ loading: true });
      this.getOffers(true);
      if (get(this.props.history.location, "state.redirectMsg")) {
        toast.error(get(this.props.history.location, "state.redirectMsg"));
        const state = { ...this.props.history.location.state };
        delete state.redirectMsg;
        this.props.history.replace({ ...this.props.history.location, state });
      }
      return true;
    }
    this.props.history.push({
      pathname: TUNE_MY_DATA,
      state: {
        reviewData: data,
        redirectMsg: "First, please verify your streaming data.",
      },
    });
  };

  saveFundingAdvance = (isCaching = false) => {
    !isCaching &&
      this.setState({ loading: false, shortLoading: true, isSaveOffer: true });
    if (!isCaching && this.isAdjustTerm()) {
      segment.track.selectedFinalOffer(this.state);
    }
    if (
      !isCaching &&
      this.checkIsFastFlow() &&
      window.location.pathname === FUNDING
    ) {
      segment.track.customizedFunding(
        {
          ...this.state,
          ...this.state.currentData,
          ...this.state.defaultOffer,
          currentData: this.state.currentData,
          advanceData: this.state.advanceData,
        },
        { track: 1 },
        true,
      );
    }

    let payload = {};

    if (!this.checkIsFastFlow()) {
      if (isModifiedFlow(this.props, this.state.isCashFlow)) {
        payload = {
          offer: {
            ...this.state.currentData,
            cashflow_option_selected: get(
              this.state,
              "defaultOffer.cashflow_option_selected",
              CASHFLOW.STANDARD,
            ),
          },
        };
      } else {
        payload = {
          offer: this.state.currentData,
        };
      }
    } else {
      const payloadObj = this.handlePayloadObj(this.state.currentData);
      payload = {
        offer: payloadObj,
      };
    }

    this.isBeatBread() &&
      Object.assign(payload, {
        switchDistributorForFunding: get(this.state, "isSwitchDistributor"),
      });

    !isCaching &&
      Object.assign(payload, {
        adjustTerms: this.isAdjustTerm() ? true : false,
      });
    const data = {
      method: isCaching ? "PUT" : "POST",
      body: payload,
    };
    const requestUrl = this.checkIsFastFlow()
      ? `${API_URL}${USER_API}${ARTIST_API}${ESTIMATED_OFFER}`
      : `${API_URL}${USER_API}${ARTIST_API}${OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        if (!isCaching) {
          this.setState({
            loading: false,
            shortLoading: false,
            isSaveOffer: false,
          });
          if (res.status) {
            if (this.state.dummyArtist) {
              this.props.history.push({
                pathname: SEND_REPORTS,
                state: { isDemo: true },
              });
              return true;
            }
            if (!this.checkIsFastFlow()) {
              const statusUpdate = this.state.offerStage;
              statusUpdate.verificationStatus.funding = VERIFIED;
              this.setState({
                offerStage: statusUpdate,
              });
            }
            if (this.isAdjustTerm()) {
              this.props.history.push(ACCEPT_OFFER);
            } else if (
              get(this.state, "rightsType") === RIGHTS_TYPES[0].value
            ) {
              this.props.history.push(PUBLISHING_REPORTS);
            } else {
              this.props.history.push(SEND_REPORTS);
            }
            toast.success(get(res, "message"));
            return true;
          }
          toast.error(get(res, "message"));
        }
      })
      .catch((err) => {
        if (!isCaching) {
          this.setState({
            shortLoading: false,
            loading: false,
            isSaveOffer: false,
          });
          toast.error(
            (toastProps) => (
              <GetErrorMessage err={err} toastProps={toastProps} />
            ),
            {
              className: "toast_hidden",
            },
          );
        }
      });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };
  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleRejectOfferModalClose = () => {
    this.setState({ isRejectOfferModalOpen: false });
  };

  newReleaseLabel = (data) =>
    getNewReleaseLabel(data, this.state.currentData.future);

  getTitle = () => (
    <>
      {this.isAdjustTerm() ? (
        <>
          <div className={styles.title}>
            <h1>Advance Structures</h1>
          </div>
          <p className={styles.subtitle}>
            We have analyzed your reports and you can now choose from any of the
            confirmed advance structures below. <br />
          </p>
        </>
      ) : (
        <>
          <div className={styles.mainHeader}>
            <Image
              src={get(this.state, "artistProfile.url")}
              alt={get(this.state, "artistProfile.name")}
              imageType={IMAGE_TYPE.PROFILE}
              className={styles.artistImage}
            />
            {BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1 && (
              <div className={styles.headerImages}>
                <BeatBreadLogo />
              </div>
            )}
            <div className={styles.headerText}>
              <p>
                Funding Options for{" "}
                <span>{get(this.state, "artistProfile.name")}</span>
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );

  toggleFinePrintText = () => {
    this.setState({ isFinePrint: !this.state.isFinePrint });
  };

  goBack = () => {
    this.props.history.push(TUNE_MY_DATA);
  };

  getMainButtons = () => {
    if (this.state.dummyArtist)
      return (
        <button
          data-testid="VerifiedButton"
          className={styles.demoBtn}
          onClick={this.saveFundingAdvance}
        >
          How Do You Send Reports to BeatBread?
          <SvgIcons icon={ARROW_FORWARD} />
        </button>
      );
    if (this.isAdjustTerm())
      return (
        <button
          data-testid="saveFunding"
          className={`${styles.primary} ${styles.longBtn}`}
          onClick={this.saveFundingAdvance}
        >
          Confirm and view offer summary
        </button>
      );
    return get(this.state.offerStage, "verificationStatus.finishingTouch") ? (
      <button
        data-testid="saveFunding"
        className={styles.primary}
        onClick={this.saveFundingAdvance}
      >
        Save funding options
      </button>
    ) : (
      <>
        <button className={styles.secondary} onClick={this.goBack}>
          Back
        </button>
        <button className={styles.primary} onClick={this.saveFundingAdvance}>
          Next
        </button>
      </>
    );
  };

  setNavigationStatus = (status, callback) => {
    this.navigationCallback = callback;
    this.setState({
      email: status.email,
      switchDistributor: get(
        status,
        "switchDistributor",
        SWITCH_DIST_OPTIONS[1].value,
      ),
      isSwitchDistributor: get(status, "switchDistributorForFunding", false),
    });
    status.isAccepted && this.props.history.push(ACCEPT_OFFER);
  };

  updateUserData = (selectedArtist) => {
    this.setState({ selectedArtist, agentCode: selectedArtist.agent_code });
    get(this.props, "history.location.pathname") === OFFER_SUMMARY &&
      this.setState({ isCashFlow: get(selectedArtist, "isCashFlow", false) });
  };

  isBeatBread = () => BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1;

  shareOfferModal = () => {
    this.setState({ isShareOfferModalOpen: !this.state.isShareOfferModalOpen });
  };

  getHeaderTitle = () => {
    if (this.isBeatBread() && !this.isAdjustTerm()) {
      return (
        <div className={styles.networkTitle}>
          <p className={styles.AdvanceEstimate}>
            <span className={styles.funding}>Advance Estimates from </span>
            beatBread and our Funding Network
          </p>
        </div>
      );
    }
    if (
      get(this.state, "offerStage.verificationStatus.isFastFlow") ||
      get(this.state, "navigationStatus.isFastFlow", false)
    ) {
      return "Review Estimates";
    } else {
      return this.isAdjustTerm() ? "Confirmed Offers" : "Customize Funding";
    }
  };

  renderWantMoreFunding = () => (
    <div className={styles.distributionPartners}>
      <div className={styles.switchContainer}>
        <Switch
          isOn={get(this.state, "isSwitchDistributor")}
          data-test="isSwitchDistributor"
          handleToggle={(e) => {
            this.setState({
              isSwitchDistributor: get(e, "target.checked"),
            });
          }}
        />
        <label>I’m willing and able to switch distributors</label>
      </div>
      <div className={styles.otherOfferContainer}>
        <p>
          <span className="textBold">
            Already have an offer outside of beatBread?{" "}
          </span>
          <button
            className={`${styles.btnLink} ${styles.otherOfferLinkBtn}`}
            onClick={this.shareOfferModal}
          >
            Share your offer with us
          </button>{" "}
          so we can leverage it to get you even better terms.
        </p>
      </div>
    </div>
  );

  setSelectedPartner = (op) => {
    this.setState(
      { isInitialPartner: false, selectedPartner: get(op, "value") },
      changeSliderCallback,
    );
  };

  togglePartnerCompareTool = () => {
    this.setState({ isComparePartner: !this.state.isComparePartner }, () => {
      if (this.state.isComparePartner && !this.state.viewedComparisonTool) {
        segment.track.viewedComparisonTable(this.checkIsFastFlow());
        this.setState({ viewedComparisonTool: true });
      }
    });
  };

  toogleRejectOffer = () => {
    this.setState({ loading: true });
    const data = {
      method: "PUT",
      body: {
        rejectOffer: true,
      },
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${REJECT_OFFER}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (get(res, "status")) {
          this.setState({ isRejectOfferModalOpen: true });
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  toggleFeatureModal = (partner) => {
    if (typeof partner === "string" && !this.state.isFeatureModalOpen) {
      this.setState({ loading: true });
      const data = {
        method: "GET",
      };
      const requestUrl = `${API_URL}${DISTRIBUTOR_FEATURE_API}?name=${encodeURIComponent(
        `${partner}`,
      )}`;
      request(requestUrl, data)
        .then((res) => {
          this.setState({ loading: false });
          if (get(res, "status")) {
            this.setState({
              isFeatureModalOpen: true,
              featureData: { partner, html: get(res, "data.featuresHTML", "") },
            });
            return true;
          }
          toast.error(get(res, "message"));
          return false;
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(
            (toastProps) => (
              <GetErrorMessage err={err} toastProps={toastProps} />
            ),
            {
              className: "toast_hidden",
            },
          );
        });
      return true;
    }
    this.setState({ isFeatureModalOpen: false });
  };

  fetchPROOptions = () => {
    segment.track.addPublishing({}, true);
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${PROS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (get(res, "status")) {
          const proOptions = [];
          const publisherOptions = [];
          invoke(res, "data.map", (o) => {
            if (get(o, "type") === TYPE_PRO) {
              proOptions.push({
                label: get(o, "name"),
                value: get(o, "_id"),
              });
            } else {
              publisherOptions.push({
                label: get(o, "name"),
                value: get(o, "_id"),
              });
            }
          });
          this.setState(
            {
              proOptions: [POR_DEFAULT_OPTION, ...proOptions, PRO_OTHER_OPTION],
              publisherOptions: [
                PUBLISHING_DEFAULT_OPTION,
                ...publisherOptions,
                PRO_OTHER_OPTION,
              ],
            },
            this.togglePublishingModal,
          );
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  getActiveDistributors = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ACTIVE_DISTRIBUTORS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (get(res, "status")) {
          const options = invoke(res, "data.map", (option) => ({
            label: get(option, "name"),
            value: get(option, "name"),
          }));
          this.setState(
            {
              distributorsOptions: [...options, ...MAJOR_DISTRIBUTOR_OPTIONS],
            },
            this.togglePublishingModal,
          );
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  handleModalSubmit = (values, form) => {
    this.setState({ loading: true });
    const isForPublishing = !get(values, ESTIMATES_FIELD_NAMES.AMOUNT.NAME);
    const payload = {
      streamingIncome:
        get(values, ESTIMATES_FIELD_NAMES.AMOUNT.NAME) || undefined,
      ...values,
    };
    delete payload[ESTIMATES_FIELD_NAMES.AMOUNT.NAME];
    const data = {
      method: isForPublishing ? "POST" : "PUT",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${
      isForPublishing ? PROS_API : MASTERS_OFFERING_API
    }`;
    request(requestUrl, data)
      .then((res) => {
        segment.track.publisherAdded({}, true);
        this.setState({ loading: false });
        invoke(form, "setSubmitting", false);
        if (get(res, "status")) {
          this.setState({ showPublishingBtn: false }, () => {
            this.setState(
              isForPublishing
                ? {
                    publishingRequestId: get(res, "data.publishingRequestId"),
                  }
                : {
                    requestId: get(res, "data.requestId"),
                  },
              () => {
                this.setRightsIncludedOptions({
                  publishingRequestId: get(this.state, "publishingRequestId"),
                  requestId: get(this.state, "requestId"),
                });
              },
            );
            this.togglePublishingModal();
            this.getOffers(false, RIGHTS_TYPES[2].value);
          });
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        invoke(form, "setSubmitting", false);
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  togglePublishingModal = () =>
    this.setState({ publishingModal: !this.state.publishingModal });

  setRightsIncludedOptions = (data) => {
    if (get(data, "requestId") && get(data, "publishingRequestId")) {
      this.setState({
        rightsIncludedOptions: RIGHTS_TYPES.map((o) => ({ ...o })),
      });
      return false;
    }
    const rightsIncludedOptions = RIGHTS_TYPES.map((o) => ({ ...o }));
    rightsIncludedOptions[2].disabled = true;
    if (!get(data, "requestId")) {
      rightsIncludedOptions[1].disabled = true;
    } else {
      rightsIncludedOptions[0].disabled = true;
    }
    this.setState({ rightsIncludedOptions, showPublishingBtn: true });
  };

  getValidationMasterSchema = () =>
    Yup.object().shape({
      [ESTIMATES_FIELD_NAMES.AMOUNT.NAME]: Yup.string()
        .required()
        .label("This"),
      [ESTIMATES_FIELD_NAMES.MAJOR_DISTRIBUTOR.NAME]: this.isBeatBread()
        ? Yup.string().nullable().required().label("This")
        : Yup.string().nullable(),
      [ESTIMATES_FIELD_NAMES.SWITCH_DISTRIBUTOR.NAME]: this.isBeatBread()
        ? Yup.string().nullable().required().label("This")
        : Yup.string().nullable(),
    });

  getValidationSchema = () =>
    Yup.object().shape({
      [PUBLISHING_FIELDS.PRO.NAME]: Yup.string().required().label("This"),
      [PUBLISHING_FIELDS.PUBLISHER.NAME]: Yup.string()
        .nullable()
        .when(PUBLISHING_FIELDS.PRO.NAME, {
          is: (val) => POR_DEFAULT_OPTION.value !== val,
          then: Yup.string().nullable().required().label("This"),
        }),
      [PUBLISHING_FIELDS.INCOME.NAME]: Yup.mixed().when(
        PUBLISHING_FIELDS.PRO.NAME,
        {
          is: (val) => POR_DEFAULT_OPTION.value !== val,
          then: Yup.number()
            .required("Your quarterly income be at least $1.")
            .min(0, "Your quarterly income be at least $1.")
            .max(
              this.state.streamingIncome *
                PUBLISHING_STREAMING_INCOME_MULTIPLIER,
              "We can't verify this quarterly income, please enter a lower revenue figure.",
            ),
        },
      ),
    });

  renderLabel = (labelText, infoText) => (
    <div className={styles.flex}>
      <div className={styles.labelText}>{labelText}</div>
      {infoText && (
        <>
          <Tooltip id={labelText} content={infoText} />
        </>
      )}
    </div>
  );

  renderPublishingForm = (form) => (
    <>
      <div className={`form-group  mb-3`}>
        <FormField
          name={PUBLISHING_FIELDS.PRO.NAME}
          as="select"
          placeholder={PUBLISHING_FIELDS.PRO.PLACEHOLDER}
          label={PUBLISHING_FIELDS.PRO.LABEL}
          data-testid="publishing-options"
          onBlur={() => {
            invoke(form, "setFieldTouched", PUBLISHING_FIELDS.PRO.NAME, true);
          }}
          components={{ IndicatorSeparator: null }}
          mainDiv={styles.mainInputContainer}
          options={this.state.proOptions}
        />
      </div>
      {get(form, `values.${PUBLISHING_FIELDS.PRO.NAME}`) !==
        POR_DEFAULT_OPTION.value && (
        <div className={`form-group  mb-3`}>
          <FormField
            name={PUBLISHING_FIELDS.PUBLISHER.NAME}
            as="select"
            placeholder={PUBLISHING_FIELDS.PUBLISHER.PLACEHOLDER}
            label={PUBLISHING_FIELDS.PUBLISHER.LABEL}
            data-testid="artist-publisher-options"
            onBlur={() => {
              invoke(
                form,
                "setFieldTouched",
                PUBLISHING_FIELDS.PUBLISHER.NAME,
                true,
              );
            }}
            components={{ IndicatorSeparator: null }}
            mainDiv={styles.mainInputContainer}
            options={this.state.publisherOptions}
          />
        </div>
      )}
      {get(form, `values.${PUBLISHING_FIELDS.PRO.NAME}`) !==
        POR_DEFAULT_OPTION.value && (
        <div className={`form-group  mb-3`}>
          <FormField
            name={PUBLISHING_FIELDS.INCOME.NAME}
            as="numeric"
            placeholder={PUBLISHING_FIELDS.INCOME.PLACEHOLDER}
            label={PUBLISHING_FIELDS.INCOME.LABEL}
            disabled={get(form, "isSubmitting")}
            className={styles.inputContainer}
            mainDiv={styles.mainInputContainer}
            prefix="$"
            suffix="  USD"
            autoComplete="off"
          />
        </div>
      )}
      <button type="submit" data-testid="publishing-submit">
        Submit
      </button>
    </>
  );

  renderMastersForm = (form) => (
    <>
      {this.isBeatBread() && (
        <>
          <div className={`form-group  mb-3`}>
            <FormField
              name={ESTIMATES_FIELD_NAMES.MAJOR_DISTRIBUTOR.NAME}
              as="select"
              placeholder={ESTIMATES_FIELD_NAMES.MAJOR_DISTRIBUTOR.PLACEHOLDER}
              label={ESTIMATES_FIELD_NAMES.MAJOR_DISTRIBUTOR.LABEL}
              data-testid="distributors-options"
              onBlur={() => {
                invoke(
                  form,
                  "setFieldTouched",
                  ESTIMATES_FIELD_NAMES.MAJOR_DISTRIBUTOR.NAME,
                  true,
                );
              }}
              components={{ IndicatorSeparator: null }}
              mainDiv={styles.mainInputContainer}
              options={this.state.distributorsOptions}
            />
          </div>
          <div className={`form-group  mb-3`}>
            <FormField
              name={ESTIMATES_FIELD_NAMES.SWITCH_DISTRIBUTOR.NAME}
              as="select"
              placeholder={ESTIMATES_FIELD_NAMES.SWITCH_DISTRIBUTOR.PLACEHOLDER}
              label={ESTIMATES_FIELD_NAMES.SWITCH_DISTRIBUTOR.LABEL}
              data-testid="switch-distributor"
              onBlur={() => {
                invoke(
                  form,
                  "setFieldTouched",
                  ESTIMATES_FIELD_NAMES.SWITCH_DISTRIBUTOR.NAME,
                  true,
                );
              }}
              components={{ IndicatorSeparator: null }}
              mainDiv={styles.mainInputContainer}
              options={SWITCH_DIST_OPTIONS}
              renderHelperLabel={
                <p className={styles.helperLabel}>
                  * Switching distributors is not required to receive funding.
                  Artists that are able and willing to switch distributors may
                  receive more choices and better terms.
                </p>
              }
              key={ESTIMATES_FIELD_NAMES.SWITCH_DISTRIBUTOR.NAME}
            />
          </div>
        </>
      )}
      <div className={`form-group  mb-3`}>
        <FormField
          name={ESTIMATES_FIELD_NAMES.AMOUNT.NAME}
          as="numeric"
          placeholder={ESTIMATES_FIELD_NAMES.AMOUNT.PLACEHOLDER}
          label={this.renderLabel(
            ESTIMATES_FIELD_NAMES.AMOUNT.LABEL,
            ESTIMATES_FIELD_NAMES.AMOUNT.TOOLTIP,
          )}
          disabled={get(form, "isSubmitting")}
          className={styles.inputContainer}
          mainDiv={styles.mainInputContainer}
          prefix="$"
          suffix="  USD"
          autoComplete="off"
        />
      </div>
      <button type="submit" data-testid="masters-submit">
        Submit
      </button>
    </>
  );

  isAskPublishing = () => !!this.state.requestId;

  renderPublishingModal = () => (
    <ReactModal
      isOpen={this.state.publishingModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.togglePublishingModal}
      className={styles.publishingPopupContainer}
      overlayClassName={styles.modalPublishingOverlay}
    >
      <div className={styles.offerContainer}>
        <button
          data-testid="publishing-close"
          className={styles.closeBtn}
          onClick={this.togglePublishingModal}
        >
          &times;
        </button>
        <div className={styles.titleContainer}>
          <h2>Add {this.isAskPublishing() ? "Publishing" : "Sound Recording"}</h2>
          <p>
            {this.isAskPublishing()
              ? "Adding publishing increase an most advances by 10% to 30%."
              : "Including sound Recording increases most artist advances by as much as 3X."}
          </p>
        </div>
        {this.isAskPublishing() ? (
          <Formik
            initialValues={PUBLISHING_INITIAL_VALUES}
            validationSchema={this.getValidationSchema()}
            onSubmit={this.handleModalSubmit}
            enableReinitialize
            data-testid="publishing-form"
          >
            {({ values, isSubmitting, setFieldTouched, handleSubmit }) => (
              <Form className={styles.publishingForm}>
                {this.renderPublishingForm({
                  values,
                  isSubmitting,
                  setFieldTouched,
                  handleSubmit,
                })}
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            initialValues={MASTERS_INIT_DATA}
            validationSchema={this.getValidationMasterSchema()}
            onSubmit={this.handleModalSubmit}
            enableReinitialize
            data-testid="masters-form"
          >
            {({ values, isSubmitting, setFieldTouched, handleSubmit }) => (
              <Form className={styles.publishingForm}>
                {this.renderMastersForm({
                  values,
                  isSubmitting,
                  setFieldTouched,
                  handleSubmit,
                })}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </ReactModal>
  );

  renderPublishingRadios = () =>
    get(this.state, "addPublisher") &&
    get(this.state, "navigationStatus.isFastFlow") && (
      <div className={styles.publisherRadios}>
        <div className={`${styles.contentname} ${styles.fastContentName}`}>
          <div className={styles.sliderData}>
            <p>Included Rights</p>
            <Tooltip
              place="top"
              light
              id="rights_included"
              delay={100}
              content={RIGHTS_INCLUDED_CONTENT}
            />
          </div>
          {this.state.showPublishingBtn && (
            <>
              <button
                onClick={
                  this.isAskPublishing()
                    ? this.fetchPROOptions
                    : this.getActiveDistributors
                }
                className={styles.linkBtn}
                data-testid="add-publishing"
              >
                <PlusIcon />
                <span>
                  Add {this.isAskPublishing() ? "Publishing" : "Sound Recording"}
                </span>
              </button>
              {this.renderPublishingModal()}
            </>
          )}
        </div>
        <FormikForm
          initialValues={{ rightsType: this.state.rightsType }}
          enableReinitialize
        >
          <Form>
            <div>
              <FormField
                as="radio"
                name="rightsType"
                radioValueList={this.state.rightsIncludedOptions}
                containerClass={styles.radioContainer}
                onChange={(e) => {
                  this.setState(
                    {
                      rightsType: get(e, "target.value", DEFAULT_RIGHTS),
                    },
                    changeSliderCallback.bind(this),
                  );
                }}
                data-testid="publishing-radio"
              />
            </div>
          </Form>
        </FormikForm>
      </div>
    );

  renderYourAdvanceContent = () => (
    <div
      className={`${styles.customizeFundingContainer} ${
        this.checkIsFastFlow() ? styles.fastCustomizeFundingContainer : ""
      } ${styles.partnerFlowContainer}`}
    >
      <div
        className={`${styles.container} ${
          this.state.navigationStatus.isFastFlow ? styles.fastContainer : ""
        }`}
      >
        {!!Object.keys(this.state.advanceData).length && (
          <>
            <div
              className={`${styles.titleContainer} ${
                this.state.navigationStatus.isFastFlow
                  ? styles.fastTitleContainer
                  : ""
              }`}
            >
              {get(this.state, "availablePartners.length") ? (
                <PartnerSelect
                  advanceData={this.state.advanceData}
                  availablePartners={this.state.availablePartners}
                  partnersOptions={this.state.partnersOptions}
                  selectedPartner={this.state.selectedPartner}
                  setSelectedPartner={this.setSelectedPartner}
                  advanceDifference={this.state.advanceDifference}
                  togglePartnerCompareTool={this.togglePartnerCompareTool}
                  isCashFlow={this.state.isCashFlow}
                />
              ) : (
                <>{this.getTitle()}</>
              )}
            </div>
            <div
              className={`${styles.sliderContainer} ${
                this.state.navigationStatus.isFastFlow
                  ? styles.fastSliderContainer
                  : ""
              }`}
            >
              {this.renderPublishingRadios()}
              {getSliderComponent.bind(this)()}
              {!this.isAdjustTerm() &&
                BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1 &&
                this.renderWantMoreFunding()}
            </div>
            <div
              className={`${styles.artistIncomeContainer} ${
                this.checkIsFastFlow() ? styles.fastArtistIncomeContainer : ""
              }`}
            >
              {this.isPartnerFlow() && (
                <p className={styles.referenceText}>
                  * This funding offer is higher than a standard beatBread
                  advance. As such, it’s likely that it will take longer to
                  recoup the advance than the stated term.
                </p>
              )}
              <div className={styles.finePrintContainer}>
                <button
                  className={styles.mobNextBtn}
                  onClick={() => this.saveFundingAdvance(false)}
                  data-testid="next-btn"
                >
                  Next
                </button>
                <p
                  className={this.checkIsFastFlow() ? styles.fastReadText : ""}
                >
                  <span onClick={this.toggleFinePrintText}>
                    Read the Fine Print
                  </span>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      {!get(this.state.navigationStatus, "isFastFlow", false) ? (
        <MenuBar
          defaultOffer={this.state.defaultOffer}
          currentData={this.state.currentData}
          offerStage={this.state.offerStage}
          advanceData={this.state.advanceData}
          isDemo={this.state.dummyArtist}
          {...this.props}
          isAdjustTerm={this.isAdjustTerm()}
          saveFundingAdvance={this.saveFundingAdvance}
          agentCode={this.state.agentCode}
          cashflow_option_selected={
            this.state.defaultOffer.cashflow_option_selected
          }
          isCashFlow={isModifiedFlow(this.props, this.state.isCashFlow)}
          availablePartners={this.state.availablePartners}
          toggleFeatureModal={this.toggleFeatureModal}
          togglePartnerCompareTool={this.togglePartnerCompareTool}
          toogleRejectOffer={this.toogleRejectOffer}
          toggleFinePrintText={this.toggleFinePrintText}
        />
      ) : (
        <ReviewEstimateContainer
          currentData={this.state.currentData}
          offerStage={this.state.offerStage}
          isDemo={this.state.dummyArtist}
          {...this.props}
          streamingIncome={this.state.streamingIncome}
          isAdjustTerm={this.isAdjustTerm()}
          checkIsFastFlow={this.checkIsFastFlow()}
          saveFundingAdvance={this.saveFundingAdvance}
          agentCode={this.state.agentCode}
          cashflow_option_selected={
            this.state.defaultOffer.cashflow_option_selected
          }
          isCashFlow={isModifiedFlow(this.props, this.state.isCashFlow)}
          isSwitchDistributor={this.state.isSwitchDistributor}
          toggleFinePrintText={this.toggleFinePrintText}
        />
      )}
      {this.isBeatBread() && !this.isAdjustTerm() && this.getHeaderTitle()}
    </div>
  );

  render() {
    return (
      <div className={styles.layoutContainer}>
        <HorizontalMenu
          offerStage={this.state.offerStage}
          isDemo={this.state.dummyArtist}
          isApprovedFlow={this.isAdjustTerm()}
          getStatus={this.setNavigationStatus}
          updateUserData={this.updateUserData}
          {...this.props}
        />
        <div className={styles.funnelContainer}>
          <LoginHeader
            isDemo={this.state.dummyArtist}
            headerTitle={this.getHeaderTitle()}
            isPartnerFlow={this.isPartnerFlow()}
          />
          <div className={styles.pageContainer}>
            <div
              className={`${styles.mainContainer} ${
                this.checkIsFastFlow() ? styles.fastMainContainer : ""
              }`}
            >
              <div className={`${styles.scrollContainer}`}>
                {!this.state.isComparePartner ? (
                  this.renderYourAdvanceContent()
                ) : (
                  <PartnerComparisonTool
                    togglePartnerCompareTool={this.togglePartnerCompareTool}
                    advanceData={this.state.advanceData}
                    currentData={this.state.currentData}
                    availablePartners={this.state.availablePartners}
                    partnersOptions={this.state.partnersOptions}
                    selectedPartner={this.state.selectedPartner}
                    setSelectedPartner={this.setSelectedPartner}
                    toggleFeatureModal={this.toggleFeatureModal}
                    cashflow_option_selected={
                      this.state.defaultOffer.cashflow_option_selected
                    }
                  />
                )}
              </div>
            </div>
          </div>
          {this.state.loading && <Loader light backgroundNone />}
          {this.state.shortLoading && (
            <ShortCallLoader
              loaderTitle="Hold tight"
              text={this.loaderText()}
              defaultLoader
            />
          )}
          {this.state.dummyArtist && (
            <FunnelCollapseGuide
              guideData={this.state.guideData}
              {...this.props}
            />
          )}
          <FeatureDataModal
            isOpen={this.state.isFeatureModalOpen}
            toggleFeatureModal={this.toggleFeatureModal}
            featureData={this.state.featureData}
          />
          <ContactPopup
            isOpen={this.state.isOpen}
            onRequestClose={this.handleClose}
          />
          <FinePrintModal
            isOpen={this.state.isFinePrint}
            onRequestClose={this.toggleFinePrintText}
          />
          <ShareOtherOffer
            isOpen={this.state.isShareOfferModalOpen}
            onRequestClose={this.shareOfferModal}
          />
          <RejectOfferSurveyModal
            isOpen={this.state.isRejectOfferModalOpen}
            onRequestClose={this.handleRejectOfferModalClose}
            name={this.state.artistProfile.name}
            email={this.state.email}
          />
        </div>
      </div>
    );
  }
}
YourAdvance.contextType = ThemeContext;
YourAdvance.contextTypes = {
  slugName: PropTypes.string,
};

export default YourAdvance;
