import React, { Component } from "react";
import { toast } from "react-toastify";
import { get } from "lodash";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  TOP_TRACK,
  ERROR_SERVER_DOWN,
  OFFER_SUMMARY,
  OFFER_SUMMARY_API,
} from "../constants";
import LoginHeader from "../../../component/LoginHeader";
import request from "../../../utils/request";
import styles from "./WelcomeBack.module.scss";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import { GetErrorMessage } from "../helper";
import HorizontalMenu from "../../../component/HorizontalMenu";
import Loader from "../../../component/Loader";
import {
  getPartnerName,
  setTitle,
} from "../../../component/ThemeManager/helper";
import { FINAL_CHECKOUT_MESSAGE } from "./constant";
import { ReactComponent as MoneyIcon } from "../../../assets/logos/money-coin-filled.svg";
import { ReactComponent as SettingIcon } from "../../../assets/logos/gear-frame.svg";
import { ReactComponent as MessageIcon } from "../../../assets/logos/envelope-solid.svg";
import { ReactComponent as TickBadgeIcon } from "../../../assets/logos/tick-badge-icon.svg";

class WelcomeBack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      availablePartners: [],
      finalCheckoutMessage: "",
      approvalMessage: "",
    };
  }

  componentDidMount() {
    this.getAvailablePartnerData();
  }

  componentDidUpdate() {
    setTitle("Welcome Back", this.context);
  }

  getAvailablePartnerData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          this.setState({
            offerStage: res.data.offerStage,
            availablePartners: get(res.data, "availablePartners", []),
            finalCheckoutMessage: get(res.data, "final_checkout_message", ""),
            approvalMessage: get(res.data, "approval_message", ""),
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  setTitle = () => <div className={styles.title}>Welcome Back!</div>;

  renderConfimedOffer = () => {
    return (
      <div className={styles.bodyContainer}>
        <div className={styles.header}>
          <MoneyIcon />
          YOUR CONFIRMED OFFERS ARE READY
        </div>
        <div className={styles.body}>
          <p>
            In a moment you’ll see your confirmed offers. Please take time to
            find the right deal structure for your needs. If you have questions
            or would like to talk it through, please contact your representative
            or drop us a note via{" "}
            <a
              href={`mailto:advances@${getPartnerName()}.com`}
              className={styles.homeLink}
            >
              {`advances@${getPartnerName()}.com`}
            </a>
            .
          </p>
          <p className={styles.boldLine}>
            Before proceeding please carefully read the notes below
          </p>
        </div>
      </div>
    );
  };

  renderFinalCheckout = () => {
    switch (this.state.finalCheckoutMessage) {
      case FINAL_CHECKOUT_MESSAGE.PARTNER_ADVANCES:
        return (
          <div className={styles.bodyContainer}>
            <div className={styles.header}>
              <SettingIcon />
              YOU CAN GET MORE MONEY BY MOVING DISTRIBUTORS
            </div>
            <div className={styles.body}>
              <p>
                Several of our distributor partners are willing to advance more
                if you are willing and able to move your tracks to them. You’ll
                see these offers on the next page and be able to compare them
                with one another and our standard {getPartnerName()} offer.
              </p>
            </div>
          </div>
        );
      case FINAL_CHECKOUT_MESSAGE.MOVE_DISTRIBUTORS:
        return (
          <div className={styles.bodyContainer}>
            <div className={styles.header}>
              <SettingIcon />
              Moving Distributors Required
            </div>
            <div className={styles.body}>
              <p>
                These offers are contingent upon all included tracks moving off
                their current distributor and to a preferred distributor.
              </p>
            </div>
          </div>
        );
      case FINAL_CHECKOUT_MESSAGE.TAKE_CONTROL_OF_ACCOUNT:
        return (
          <div className={styles.bodyContainer}>
            <div className={styles.header}>
              <SettingIcon />
              IMPORTANT MESSAGE ABOUT YOUR DISTRIBUTION
            </div>
            <div className={styles.body}>
              <p>
                These offers are contingent on us having full control of your
                distribution account. This means we'll change the email address
                & password associated with the account and re-direct the revenue
                to us to recoup your advance. Once your advance has been
                recouped and your term is up, we’ll turn the account back over
                to you.
              </p>
              <p>
                You will not have access to this account during the term, but an
                experienced team member will be available for any requests you
                have regarding new release setup, reporting, or other
                distributor requests that you would normally do on the platform.
              </p>
              <p>
                If you are not comfortable with this, please let us know and we
                can discuss catalog transfer options and move your releases to
                another distributor that will allow you full access during your
                term.
              </p>
            </div>
          </div>
        );
      case FINAL_CHECKOUT_MESSAGE.SECURE_INCOME:
        return (
          <div className={styles.bodyContainer}>
            <div className={styles.header}>
              <SettingIcon />
              IMPORTANT MESSAGE ABOUT YOUR DISTRIBUTION
            </div>
            <div className={styles.body}>
              <p>
                These offers are contingent upon your distributor accepting a
                Letter of Direction and {getPartnerName()} securing the income
                from all included tracks, or moving all included tracks can move
                to an approved distributor.
              </p>
            </div>
          </div>
        );
      case FINAL_CHECKOUT_MESSAGE.MUST_MOVE_PARTNER_ADVANCE:
        return (
          <div className={styles.bodyContainer}>
            <div className={styles.header}>
              <SettingIcon />
              YOU CAN GET MORE MONEY BY MOVING DISTRIBUTORS
            </div>
            <div className={styles.body}>
              <p>
                Several of our distributor partners are willing to advance more
                than we would, if you are willing and able to move your tracks
                to them. You’ll see these offers on the next page and be able to
                compare them with one another and our standard{" "}
                {getPartnerName()} offer. In any case you will need to move all
                included tracks to an approved distributor in order to finalize
                your advance.
              </p>
            </div>
          </div>
        );
      default:
        return false;
    }
  };

  renderApprovedMessage = () => {
    if (get(this.state, "approvalMessage", "")) {
      return (
        <div className={styles.bodyContainer}>
          <div className={styles.header}>
            <MessageIcon />A message from our team
          </div>
          <div className={styles.body}>
            <p>{this.state.approvalMessage}</p>
          </div>
        </div>
      );
    } else {
      return false;
    }
  };

  saveViewOfferStage = () => {
    this.setState({ loading: true });
    const payload = {
      offerSummary: true,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${OFFER_SUMMARY_API}`;
    request(requestURL, data)
      .then((res) => {
        if (!res.status) {
          this.setState({ loading: false });
          toast.error(get(res, "message"));
          return [];
        }
        this.props.history.push(OFFER_SUMMARY);
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.setState({ loading: false });
      });
    return true;
  };

  render() {
    return (
      <div className={styles.layoutContainer}>
        <HorizontalMenu offerStage={this.state.offerStage} {...this.props} />
        <div className={styles.funnelContainer}>
          <LoginHeader />
          <div className={styles.pageContainer}>
            <div className={styles.mainContainer}>
              <div className={styles.scrollContainer}>
                <div className={styles.welcomeSubContainer}>
                  <div className={styles.container}>
                    <div className={styles.badgeContainer}>
                      <TickBadgeIcon />
                    </div>
                    <div className={styles.titleContainer}>
                      <div className={styles.mobileBadgeCtn}>
                        <TickBadgeIcon />
                      </div>
                      {this.setTitle()}
                    </div>
                    {this.renderConfimedOffer()}
                    {this.renderFinalCheckout()}
                    {this.renderApprovedMessage()}
                  </div>
                  <div className={styles.viewOfferButtonContainer}>
                    <button
                      className={styles.viewOfferBtn}
                      data-testid="ViewOfferButton"
                      onClick={() => this.saveViewOfferStage()}
                    >
                      view offers
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loading && <Loader light />}
      </div>
    );
  }
}
WelcomeBack.contextType = ThemeContext;

export default WelcomeBack;
