/* Constants for API */
export const API_URL = process.env.REACT_APP_API_URL;
export const AUTH = "/auth";
export const SIGNIN_API = "/signin";
export const CODE_API = "/code";
export const CHANGE_PASS_API = "/change-password";
export const VALIDATE_AUTH_API = "/validateAuthentication";
export const REGISTER_API = "/register";
export const PARTNER_USER_REGISTER_API = "/partner-user-registration";
export const FORGOT_PASS_API = "/forgot-password";
export const GETTOKEN_API = "/social-media/url";
export const VALIDATEAUTH_API = "/validateAuthentication";
export const USER_API = "/user";
export const SPOTIFY_USER_API = "/spotify-users";
export const SIGNUP_API = "/signup";
export const USERADD = "/social-media/add";
export const USERREMOVE = "/social-media/remove";
export const ME = "/me";
export const ADMIN = "/admin";
export const ARTIST_API = "/artist";
export const ACTIVATE_API = "/activate";
export const STATUS_API = "/status";
export const CHANGE_VISIT_STATUS = "/change-visit-status";
export const VERIFICATION_RESEND = "/verification/resend";
export const RESEND_VERIFICATION = "/resend-verification";
export const REPORTS = "/reports";
export const VERIFICATION = "/verification";
export const PROVISIONAL = "/add/provisional";
export const ARTIST_SOCIALMEDIA = "/add/social-media";
export const ADD_ARTIST = "/add";
export const ADD_ARTIST_FAST_FLOW = "/add-fast-flow";
export const FUNDING_OPTION = "/offer-generation";
export const VERIFY_STREAMING_INCOME = "/update-streaming-income";
export const OFFERS_API = "/offers";
export const ADMIN_USERS = "/admin/users";
export const ADMIN_USER = "/admin/user";
export const ADMIN_ARTIST_API = "/admin/artist";
export const REPROCESS_API = "/reprocess";
export const REVOKE_APPROVAL_API = "/revoke-approval";
export const ADMIN_ACTIVATE_USER = "/admin/user/activate";
export const CHAT_API = "/chat";
export const NOTIFY_APPOINTMENT = "/notify-appointment";
export const SOCIAL_NETWORK = "/social-networks";
export const EMAIL_PREFERENCES_API = "/email-preferences";
export const ADMIN_WHITELABEL_API = "/admin/whitelabel";
export const ADMIN_WHITELABEL_ACCOUNTS = "/admin/whitelabel/accounts";
export const ADMIN_MAINTENANCE_MODE_API = "/admin/maintenance-mode";
export const DISTRIBUTORS_API = "/distributor";
export const INFO_API = "/info";
export const ACTIVE_DISTRIBUTORS = "/active-distributors";
export const DISTRIBUTOR_REPORTS_API = "/distributor-reports";
export const DISTRIBUTOR_FEATURE_API = "/distributor/feature";
export const OTHER_REPORTS_API = "/other-reports";
export const CHANGE_STATE = "/change-state";
export const INSTANCE = "/instance";
export const ADMIN_IP_API = "/admin/ip";
export const USER_GUIDE = "/user-guide";
export const REPORT = "/report";
export const SEND_REPORTS_API = "/send-reports";
export const OFFER_CONTRACT_PDF = "/offer-contract-pdf";
export const DOWNLOAD_REPORTS = "/download-reports";
export const OFFER_SUMMARY_API = "/offer-summary";
export const EMAIL_OFFER_API = "/email-offer";
export const EXIT_DEMO = "/exit-demo";
export const DEMO_ARTIST_DATASET = "/demo-artist-data-sets";
export const DEMO_ARTIST_API = "/demo-artist";
export const SAVE_DEMO_ARTIST = "/save-demo-artist";
export const ACTIVE_DUMMY_ARTIST = "/active-dummy-artist";
export const TRACK_REASON_UPDATE = "/track-reason-update";
export const NAVIGATION_STATUS_API = "/navigation-status";
export const UPDATE_EMAIL = "/update-email";
export const UPDATE_SPOTIFY_PROFILE_API = "/update-spotify-profile";
export const INVITE_BIDDER = "/invite-bidder";
export const SAVE_UPCOMING_PROJECTS = "/save-upcoming-projects";
export const UPDATE_MARKETING_STATUS = "/update-marketing-status";
export const PLAID_IDENTITY_VERIFICATION = "/identity-verification";
export const ONBOARDING_DETAILS = "/onboarding-details";
export const CREATE_LINK_TOKEN = "/create-link-token";
export const VALIDATE_ROUTING_NUMBER = "/validate-routing-number";
export const LINK_BANK_MANUALLY = "/link-bank-manually";
export const EXCHANGE_PUBLIC_TOKEN = "/exchange-public-token";
export const RE_AUTHENTICATE_LINK = "/re-authenticate-account";
export const UPDATE_KYC_REASON = "/update-kyc-reason";
export const BANK_INFORMATION = "/bank-information";
export const SEND_MICRO_DEPOSIT = "/send-micro-deposit";
export const MICRO_DEPOSIT = "/validate-micro-deposit";
export const REFRESH_TOKEN = "/refresh-token";
export const HOMEPAGE_CONTENT_LIST = "/admin/homepage/content-list";
export const HOMAPAGE_CATEGORY = "/admin/homepage/tag-category";
export const HOMEPAGE_CONTENT_MANAGEMENT = "/admin/homepage/content-management";
export const HOMEPAGE = "/homepage";
export const TAG_CATEGORY = "/tags";
export const CONTENT_MANAGEMENT = "/content-management";
export const FUNNEL_GUIDE_VIDEO = "/funnel-guide-video";
export const ESTIMATED_OFFER = "/estimated-offer";
export const CONFIRMED_OFFERS_API = "/confirmed-offers";
// export const CONFIRMED_OFFERS_API = "/offers";
export const SECURE_LINK = "/secure-link";
export const SEGMENT_DATA = "/segment/data";
export const PUBLISHER_API = "/publisher";
export const PUBLISHER_REPORTS_API = "/publisher-report";
export const PUBLISHER_OFFER_API = "/publishing-offer";
export const VALIDATE_EMAIL = "/validate-email";
export const ARTIST_ESTIMATE = "/user-enrollment";
export const ARTIST_FUNDING = "/artist-funding";
export const PROS_API = "/pro-publisher";
export const REJECT_OFFER = "/reject-offer";
export const CHOOSE_PATH_API = "/choose-path";
export const MASTERS_OFFERING_API = "/masters-offering";
export const OTHER_OFFER_DOCUMENT = "/other-offer-document";
export const OTHER_OFFERS = "/other-offer-info";
export const OWNERSHIP_TRACKS = "/tracks-ownership";
export const CONFIRMED_OFFER_STEP = "/confirmed-offer-step";

/* Constants for Routes */

export const HOME = "/";
export const ERROR_PAGE = "/error-404";
export const ERROR_SERVER_DOWN = "/error-503";
export const ERROR_FORBIDDEN = "/error-403";
export const MAINTENANCE_PAGE = "/maintenance";
export const LOGIN = "/login";
export const ADMIN_LOGIN = "/admin/login";
export const FORGOT_PASS = "/forgot-password";
export const CHANGE_PASS = "/change-password";
export const REGISTER = "/register";
export const VERIFY_USER = "/verify-user";
export const STATS = "/stats";
export const ARTISTSTATS = "/artist/stats";
export const TOP_TRACK = "/top-tracks";
export const SPOTIFY_STATS = "/spotify-stats";
export const SOUNDCLOUD_STATS = "/soundcloud-stats";
export const YOUTUBE_STATS = "/youtube-stats";
export const INSTAGRAM_STATS = "/instagram-stats";
export const GETFUNDED = "/get-funded";
export const GETFUNDED_AUTH = "/register";
export const MY_ACCOUNT = "/my-account";
export const DASHBOARD = "/dashboard";
export const ADMIN_DASHBOARD = "/admin/users";
export const ADMIN_BLACKLIST = "/admin/blacklist";
export const ADMIN_WHITELABEL = "/admin/whitelabel";
export const ADMIN_WHITELABEL_NEW_ACCOUNT = "/admin/whitelabel/create";
export const ADMIN_WHITELABEL_EDIT_ACCOUNT = "/admin/whitelabel/edit";
export const ADMIN_MAINTENANCE_MODE = "/admin/maintenance";
export const DISTRIBUTORS = "/admin/distributors";
export const DISTRIBUTORS_CREATE = "/admin/distributors/create";
export const DISTRIBUTORS_EDIT = "/admin/distributors/edit";
export const PUBLISHERS = "/admin/publisher";
export const PUBLISHERS_CREATE = "/admin/publisher/create";
export const PUBLISHERS_EDIT = "/admin/publisher/edit";
export const DEMO_ARTIST = "/admin/demo-artists";
export const DEMO_ARTIST_CREATE = "/admin/demo-artists/create";
export const DEMO_ARTIST_EDIT = "/admin/demo-artists/edit";
export const ACTIVE_API = "/active";
export const DATA_REVIEW = "/data-review";
export const FUNDING = "/customize-funding";
export const SEND_REPORTS = "/send-reports";
export const PUBLISHING_REPORTS = "/publishing-reports";
export const VERIFY = "/verify";
export const LINK_ANOTHER_ARTIST = "/link-another-artist";
export const CONTACT_BEATBREAD_GET_FUNDED = "/contact-for-funding";
export const SUCCESS = "/success";
export const WHY_BEATBREAD = "/why-beatbread";
export const LINK_SPOTIFY = "/finishing-touches";
export const SOCIAL_NETWORKS = "/edit-profile";
export const GENERATING_OFFERS = "/generating-offers";
export const CONFIRM_OWNERSHIP = "/confirm-ownership";
export const MANAGE_BLACKLIST = "/manage-blacklist";
export const EMAIL_PREFERENCES = "/email-preferences";
export const TUNE_MY_DATA = "/verify-data";
export const ACK_IN_RANGE_1 = "/acknowledgement-screen-0-1";
export const ACK_IN_RANGE_2 = "/acknowledgement-screen-0-2";
export const ACK_IN_RANGE_3 = "/acknowledgement-screen-0-3";
export const ACK_IN_RANGE_4 = "/acknowledgement-screen-0-4";
export const ACK_HIGH = "/acknowledgement-screen-1";
export const ACK_LOW = "/acknowledgement-screen-2";
export const ACK_ERROR = "/acknowledgement-screen-3";
export const ACK_NEW = "/acknowledgement-screen-4";
export const ACK_REDIRECT = "/redirect";
export const OFFER_SUMMARY = "/confirmed-offers";
export const CONFIRMED_OFFERS_V2 = "/v2/confirmed-offers";
export const WELCOME_BACK = "/welcome-back";
export const ADJUST_TERMS = "/adjust-terms";
export const ACCEPT_OFFER = "/deal-summary";
export const ARTIST_VERIFY = "/artist-verify";
export const DEMO_DASHBOARD = "/demo-dashboard";
export const ARTIST_ONBOARD = "/marketplace/artist-onboard";
export const GET_EVEN_MORE = "/marketplace/get-even-more";
export const SET_TARGET_ADVANCE = "/marketplace/set-target-advance";
export const UPCOMING_WORKS = "/marketplace/upcoming-works";
export const INVITE_BIDDERS = "/marketplace/invite-bidders";
export const POST_CONFIRMATION = "/marketplace/post-confirmation";
export const ARTIST_REGISTRATION = "/marketplace/artist-registration";
export const POSTED_TO_NETWORK = "/marketplace/posted-network";
export const LOGGED_OUT = "/logged-out";
export const WAITING_ROOM = "/waiting-room";
export const ONBOARDING_FORM_REDIRECTION = "/onboarding-form-submitted";
export const REGISTER_EVENT_API = "/register-event";
export const BIDDERS = "/invite-bidder";
export const INVITE_BIDDERS_PREVIEW = "/marketplace/invite-bidders-preview";
export const FINAL_CHECKOUT_ONBOARDING = "/accept-offer/onboarding-form";
export const MARKETPLACE_ONBOARDING = "/marketplace/onboarding-form";
export const HOMEPAGE_CONTENT_NEW = "/admin/homepage-content/create";
export const HOMEPAGE_CONTENT_EDIT = "/admin/homepage-content/edit";
export const HOMEPAGE_CONTENT = "/admin/homepage-content";
export const SIGNUP = "/signup";
export const FF_SECURE_LOGIN = "/secure-login";
export const FF_VALIDATE = "/validate-link";
export const VALIDATE_PARTNER = "/validate-partner";
export const PRIVACY = "/privacy";

/* Contact mail */

export const CONTACT_EMAIL = "hello@[DOMAIN].com";
export const HOMEPAGE_URL = `${process.env.REACT_APP_HOMEPAGE_URL}`;
export const INVESTOR_LINK = `${process.env.REACT_APP_HOMEPAGE_URL}/investors`;
export const OUR_ARTIST = `${process.env.REACT_APP_HOMEPAGE_URL}/#testimonials`;
export const PARTNER_LINK = `${process.env.REACT_APP_HOMEPAGE_URL}/partners`;
export const ABOUT_US_LINK = `${process.env.REACT_APP_HOMEPAGE_URL}/our-mission`;
export const FAQ_LINK = `${process.env.REACT_APP_HOMEPAGE_URL}/faq`;
export const BB_LINK = "https://beatbread.com";
export const BB_DEV_LINK = "https://stgwp.beatbread.com/demo-page";
export const BB_DEMO_LINK = "https://stgwp.beatbread.com";
export const CHORDCASH_PRIVACY = "https://chordcash.com/privacy-policy";
export const CHORDCASH_CALENDLY_LINK =
  "https://calendly.com/conner-davis-1/30-minute-meeting";
export const BEATBREAD_CALENDLY_LINK =
  "https://calendly.com/d/ckr-fq9-vm7/review-your-deal-terms-with-beatbread";
export const BEATBREAD_MARKETPLACE_CALENDLY_LINK =
  "https://calendly.com/matthew-bb/beatbread-investor-network-overview";
export const FAILED_TO_FETCH =
  "We're experiencing higher than normal traffic, which is causing an issue. Please try again in a moment.";
export const YT_THUMBNAIL_URL =
  "https://img.youtube.com/vi/[VIDEO_ID]/sddefault.jpg";
export const YT_EMBED_URL =
  "https://www.youtube-nocookie.com/embed/[VIDEO_ID]?rel=0&autoplay=1&modestbranding=1";
export const INSTRUCTIONAL_YT_VIDEO =
  "https://www.youtube.com/watch?v=t3YUN78IX7A";
export const YT_EMBED_URL_NO_AUTOPLAY =
  "https://www.youtube-nocookie.com/embed/[VIDEO_ID]?rel=0&modestbranding=1";
/* Social media platforms */
export const INSTAGRAM_PLATFORM = "instagram";
export const YOUTUBE_PLATFORM = "youtube";
export const FACEBOOK_PLATFORM = "facebook";
export const WIKIPEDIA_PLATFORM = "wikipedia";
export const READ_ONLY_TEXT_LENGTH = 175;
export const ARTIST_BIO_MAX_CHARACTERS = 1500;

/* Intercom Constants */
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
