import React, { Component } from "react";
import styles from "./ConfirmedOffersV2.module.scss";
import {
  MAIN_QUESTION_OPTIONS,
  DEAL_OPTIONS,
  CONFIRMED_OFFER_QUESTIONS,
  OFFERSUMMARY_STEPS,
  MUSIC_OPTION,
  INCLUDE_UNRELEASE_SONG_OPTION,
  DEAL_LENGTH_OPTIONS,
  INCOME_OPTIONS,
  SLIDER_STEP_RANGE,
} from "./constants";
import {
  API_URL,
  ARTIST_API,
  CONFIRMED_OFFER_STEP,
  USER_API,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage } from "../helper";
import { get, invoke } from "lodash";
import ReactRange from "../../../component/Range/ReactRange";
import { getShortNumber } from "../YourAdvance/helper";
import Odometer from "react-odometerjs";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import Tooltip from "../../../component/Tooltip/Tooltip";

class ConfirmedOffersV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      step: 1,
      selectedOptions: {},
      sliderValue: undefined,
      selectedSteps: [1],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      get(prevProps, "offerMinMax.min") !== get(this.props, "offerMinMax.min")
    ) {
      const defaultVal =
        get(this.props, "offerMinMax.min") + this.calculateSteps() * 3;
      if (defaultVal <= get(this.props, "offerMinMax.max")) {
        this.setState({ sliderValue: defaultVal });
      } else {
        this.setState({ sliderValue: get(this.props, "offerMinMax.min") });
      }
    }
  }

  setMainOptions = (option) => {
    let step;
    if (option === MAIN_QUESTION_OPTIONS[0].OPTION) {
      step = 4;
    } else if (option === MAIN_QUESTION_OPTIONS[1].OPTION) {
      step = 2;
    } else {
      this.saveConfirmedOfferSteps();
      return true;
    }
    this.setState((prevStep) => {
      const updatedSteps = [...(prevStep.selectedSteps || [])];
      if (!updatedSteps.includes(step)) {
        updatedSteps.push(step);
      }
      return {
        step,
        selectedSteps: updatedSteps,
      };
    });
  };

  setDealOptions = (option) => {
    let step;
    if (option === DEAL_OPTIONS[0].OPTION) {
      step = 5;
    } else if (option === DEAL_OPTIONS[1].OPTION) {
      step = 6;
    } else {
      step = 7;
    }
    this.setState((prevStep) => {
      const updatedSteps = [...(prevStep.selectedSteps || [])];
      if (!updatedSteps.includes(step)) {
        updatedSteps.push(step);
      }
      return {
        step,
        selectedSteps: updatedSteps,
      };
    });
  };

  saveConfirmedOfferSteps = () => {
    invoke(this.props, "setLoading", true);
    const payload = {
      preference: get(this.state, "selectedOptions.MAIN_STEP"),
      dealType: get(this.state, "selectedOptions.DEAL_QUESTION"),
      term: get(this.state, "selectedOptions.DEAL_LENGTH_QUESTION"),
      royalty: get(this.state, "selectedOptions.INCOME_QUESTION"),
      works: get(this.state, "selectedOptions.INCLUDE_UNRELEASE_SONG"),
      funding: get(this.state, "selectedOptions.FUNDING_QUESTION"),
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CONFIRMED_OFFER_STEP}`;
    request(requestUrl, data)
      .then((res) => {
        invoke(this.props, "setLoading", false);
        if (res.status) {
          // Send to confirmed offer screen
          if (payload.preference === MAIN_QUESTION_OPTIONS[2].OPTION) {
            invoke(this.props, "setParentState", { isOfferScreen: true });
            return false;
          }
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        invoke(this.props, "setLoading", true);
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  setMusicOptions = (option) => {
    let step;
    if (option === MUSIC_OPTION[0].OPTION) {
      step = 8;
    } else {
      step = 9;
    }
    this.setState((prevStep) => {
      const updatedSteps = [...(prevStep.selectedSteps || [])];
      if (!updatedSteps.includes(step)) {
        updatedSteps.push(step);
      }
      return {
        step,
        selectedSteps: updatedSteps,
      };
    });
    if (step === 9) {
      this.saveConfirmedOfferSteps();
    }
  };

  setDealLengthOption = (option) => {
    const step = 5;
    this.setState((prevStep) => {
      const updatedSteps = [...(prevStep.selectedSteps || [])];
      if (!updatedSteps.includes(step)) {
        updatedSteps.push(step);
      }
      return {
        step,
        selectedSteps: updatedSteps,
      };
    });
  };

  setIncomeOption = (option) => {
    const step = 5;
    this.setState((prevStep) => {
      const updatedSteps = [...(prevStep.selectedSteps || [])];
      if (!updatedSteps.includes(step)) {
        updatedSteps.push(step);
      }
      return {
        step,
        selectedSteps: updatedSteps,
      };
    });
  };

  updateOfferSummaryModule = (option, stepValue) => {
    this.setState(
      (prevState) => ({
        selectedOptions: {
          ...prevState.selectedOptions,
          [stepValue]: option,
        },
      }),
      () => {
        if (stepValue === OFFERSUMMARY_STEPS[0]) {
          this.setMainOptions(option);
        } else if (stepValue === OFFERSUMMARY_STEPS[1]) {
          this.setDealOptions(option);
        } else if (stepValue === OFFERSUMMARY_STEPS[2]) {
          this.setMusicOptions(option);
        } else if (stepValue === OFFERSUMMARY_STEPS[4]) {
          this.setDealLengthOption(option);
        } else if (stepValue === OFFERSUMMARY_STEPS[6]) {
          this.setIncomeOption(option);
        } else if (stepValue === OFFERSUMMARY_STEPS[3]) {
          this.saveConfirmedOfferSteps();
        } else if (stepValue === OFFERSUMMARY_STEPS[5]) {
          this.setIncomeOption(option);
        }
      },
    );
  };

  renderOptionContainer = (questionOptions, stepValue) => {
    const lastSelectedOption = this.state.selectedOptions[stepValue] || null;
    return (
      <>
        {questionOptions.map((option) => (
          <button
            className={`${styles.optionContainer} ${
              lastSelectedOption === option.OPTION
                ? styles.highlightedOption
                : ""
            }`}
            key={option.OPTION}
            onClick={() =>
              this.updateOfferSummaryModule(option.OPTION, stepValue)
            }
          >
            <h2>{option.OPTION}</h2>
            {option.DESCRIPTION && <p>{option.DESCRIPTION}</p>}
          </button>
        ))}
      </>
    );
  };

  renderToBackPage = (stepValue) => {
    this.setState((prevState) => {
      const updatedStep = [...prevState.selectedSteps];
      if (updatedStep.length > 1) {
        updatedStep.pop();
      }
      const previousStep = updatedStep[updatedStep.length - 1] || null;
      const updatedSelectedOptions = { ...prevState.selectedOptions };

      if (stepValue in updatedSelectedOptions) {
        delete updatedSelectedOptions[stepValue];
      }

      this.setState({
        step: previousStep,
        selectedSteps: updatedStep,
        selectedOptions: updatedSelectedOptions,
      });
    });
  };

  getQuestionAnswerContainer = (
    selectedQuestion,
    questionOptions,
    stepValue,
  ) => {
    return (
      <>
        <div className={styles.mainQuestionContainer}>
          <div className={styles.titleQuestionContainer}>
            <h2>{selectedQuestion}</h2>
          </div>
          <div className={styles.answerContainer}>
            {this.renderOptionContainer(questionOptions, stepValue)}
          </div>
        </div>
        {selectedQuestion !== CONFIRMED_OFFER_QUESTIONS.MAIN_QUESTION ? (
          <button
            className={styles.goBackBtn}
            onClick={() => this.renderToBackPage(stepValue)}
          >
            Go Back
          </button>
        ) : (
          <span />
        )}
      </>
    );
  };

  calculateSteps = () => {
    if (get(this.props, "offerMinMax.max", 0) <= SLIDER_STEP_RANGE[0])
      return 1000;
    if (get(this.props, "offerMinMax.max", 0) <= SLIDER_STEP_RANGE[1])
      return 5000;
    if (get(this.props, "offerMinMax.max", 0) <= SLIDER_STEP_RANGE[2])
      return 10000;
    return 50000;
  };

  handleSliderButton = (isPlus) => {
    this.setState((prev) => {
      const value = isPlus
        ? get(prev, "sliderValue", 0) + this.calculateSteps()
        : get(prev, "sliderValue", 0) - this.calculateSteps();
      if (value < get(this.props, "offerMinMax.min", 0))
        return { sliderValue: get(this.props, "offerMinMax.min", 0) };
      if (value > get(this.props, "offerMinMax.max", 0))
        return { sliderValue: get(this.props, "offerMinMax.max", 0) };
      return { sliderValue: value };
    });
  };

  renderFundingSlider = (selectedQuestion, stepValue) => (
    <>
      <div className={styles.mainQuestionContainer}>
        <div className={styles.titleQuestionContainer}>
          <h2>{selectedQuestion}</h2>
        </div>
        <div className={styles.answerContainer}>
          <div className={styles.fundingSlider}>
            <div className={styles.sliderValContainer}>
              <button
                className={styles.sliderBtn}
                onClick={() => {
                  this.handleSliderButton();
                }}
                data-testid="sliderBtn"
              >
                <RemoveCircleOutline />
              </button>
              {!!get(this.state, "sliderValue") && (
                <span>
                  $
                  <Odometer
                    value={getShortNumber(get(this.state, `sliderValue`))}
                    format="(,ddd).dd"
                    duration={400}
                  />
                  {`${getShortNumber(get(this.state, `sliderValue`))}`.replace(
                    /[\d,\.]/g,
                    "",
                  )}
                </span>
              )}
              <button
                className={styles.sliderBtn}
                onClick={() => {
                  this.handleSliderButton(true);
                }}
                data-testid="sliderBtn"
              >
                <AddCircleOutline />
              </button>
            </div>
            <div className={styles.sliderContainer}>
              <span className={styles.minMax}>
                ${getShortNumber(get(this.props, "offerMinMax.min"))}
              </span>
              <ReactRange
                values={[
                  get(
                    this.state,
                    "sliderValue",
                    get(this.props, "offerMinMax.min"),
                  ),
                ]}
                onFinalChange={(val) => {
                  this.setState({ sliderValue: val[0] });
                }}
                min={get(this.props, "offerMinMax.min")}
                max={get(this.props, "offerMinMax.max")}
                step={this.calculateSteps()}
                hideValue
              />
              <span className={styles.minMax}>
                ${getShortNumber(get(this.props, "offerMinMax.max"))}
              </span>
            </div>
            <button
              className={styles.sliderSubmit}
              onClick={() => {
                this.updateOfferSummaryModule(
                  get(this.state, "sliderValue"),
                  stepValue,
                );
              }}
              data-testid="sliderContinue"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      {!(selectedQuestion === CONFIRMED_OFFER_QUESTIONS.MAIN_QUESTION) && (
        <button
          className={styles.goBackBtn}
          onClick={() => this.renderToBackPage(stepValue)}
        >
          Go Back
        </button>
      )}
    </>
  );

  renderComponentByStep = () => {
    const { step } = this.state;
    switch (step) {
      case 1:
        return this.getQuestionAnswerContainer(
          CONFIRMED_OFFER_QUESTIONS.MAIN_QUESTION,
          MAIN_QUESTION_OPTIONS,
          OFFERSUMMARY_STEPS[0],
        );
      case 2:
        return this.renderFundingSlider(
          CONFIRMED_OFFER_QUESTIONS.FUNDING_SLIDER_QUESTION,
          OFFERSUMMARY_STEPS[5],
        );
      case 4:
        return this.getQuestionAnswerContainer(
          CONFIRMED_OFFER_QUESTIONS.DEAL_QUESTION,
          DEAL_OPTIONS,
          OFFERSUMMARY_STEPS[1],
        );
      case 5:
        return this.getQuestionAnswerContainer(
          CONFIRMED_OFFER_QUESTIONS.MUSIC_QUESTION,
          MUSIC_OPTION,
          OFFERSUMMARY_STEPS[2],
        );
      case 6:
        return this.getQuestionAnswerContainer(
          CONFIRMED_OFFER_QUESTIONS.DEAL_LENGTH_QUESTION,
          DEAL_LENGTH_OPTIONS,
          OFFERSUMMARY_STEPS[4],
        );
      case 7:
        return this.getQuestionAnswerContainer(
          CONFIRMED_OFFER_QUESTIONS.INCOME_QUESTION,
          INCOME_OPTIONS,
          OFFERSUMMARY_STEPS[6],
        );
      case 8:
        return this.getQuestionAnswerContainer(
          CONFIRMED_OFFER_QUESTIONS.INCLUDE_UNRELEASE_SONG_QUESTION,
          INCLUDE_UNRELEASE_SONG_OPTION,
          OFFERSUMMARY_STEPS[3],
        );
    }
  };

  render() {
    return (
      <div className={styles.confirmedOfferContainer}>
        <div className={styles.mobOfferRange}>
          <p>
            <span>Confirmed Offers Range</span>{" "}
            <Tooltip
              place="right"
              light
              id="offerRange"
              content={
                "Confirmed Offers are Subject to Validation and Final Contract"
              }
              delay={200}
            />
          </p>
          <div className={styles.rangeNumbers}>
            ${getShortNumber(get(this.props, "offerMinMax.min"))} - $
            {getShortNumber(get(this.props, "offerMinMax.max"))}
          </div>
        </div>
        {this.renderComponentByStep()}
      </div>
    );
  }
}

export default ConfirmedOffersV2;
